import { isAxiosError } from '@/api/business'
import { fetchCurrencies } from '@/api/business/company'
import { useToast } from '@/components/ui/use-toast'
import { Company } from '@/api/business/company/types'
import { useQuery } from '@tanstack/react-query'

export const companyKeys = {
    all: ['company'] as const,
    lists: () => [...companyKeys.all, 'list'] as const,
}

interface CompanyQuery {
    onSuccess?: (data: Company[]) => void
}

export const useCompanyQuery = (props?: CompanyQuery) => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchCurrencies(),
        queryKey: companyKeys.lists(),
        staleTime: Infinity,
        onSuccess: props?.onSuccess,
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar empresas. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
