// import { ResponseData } from '@/api/business/structureStatus/type'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import { useStructureStatusByIdQuery } from '@/queries/useStructureStatusQuery'
import { ReactNode, useState } from 'react'
import { useParams } from 'react-router-dom'
import HistoryTabSection from './components/HistoryTabSection'
import FirstVersionTabSection from './components/FirstVersionTabSection'
import CurrentVersionTabSection from './components/CurrentVersionTabSection'
import { cn } from '@/lib/utils'
import { HistoryIcon } from 'lucide-react'

// type HistoryDetailDialog = {
//     data: ResponseData
// }

type TabValues = 'complete' | 'first' | 'current'

type Tabs = {
    label: string
    value: TabValues
    component: ReactNode
}

const tabs: Tabs[] = [
    {
        label: 'Versão atual',
        value: 'current',
        component: <CurrentVersionTabSection />,
    },

    {
        label: 'Primeira versão',
        value: 'first',
        component: <FirstVersionTabSection />,
    },
    {
        label: 'Histórico completo',
        value: 'complete',
        component: <HistoryTabSection />,
    },
]

const HistoryDetailDialog = () => {
    const { id } = useParams()
    const [selectedTab, setSelectedTab] = useState(tabs[0])

    const { data: structure } = useStructureStatusByIdQuery({
        id: id!,
    })

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button
                    variant="ghost"
                    size="icon"
                    className="text-primary hover:text-primary"
                >
                    <HistoryIcon size={18} />
                </Button>
            </DialogTrigger>
            <DialogContent className="flex flex-col max-w-[1400px] max-h-[90%] h-[720px]">
                <DialogHeader>
                    <DialogTitle>Histórico</DialogTitle>
                    <DialogDescription>
                        Histórico detalhado do produto{' '}
                        {structure?.DS_PRODUTO_ESTRUTURA}
                    </DialogDescription>
                </DialogHeader>
                <div className="flex flex-1 overflow-hidden">
                    <div className="w-[200px] p-2 bg-muted rounded-md">
                        {tabs.map((tab) => {
                            const isSelected = tab.value === selectedTab.value

                            return (
                                <div
                                    className={cn(
                                        'px-4 py-2.5 hover:bg-slate-200 dark:hover:bg-slate-700 cursor-pointer',
                                        isSelected &&
                                            'text-primary bg-slate-200 dark:bg-slate-700 dark:text-primary-400'
                                    )}
                                    key={tab.value}
                                    onClick={() => setSelectedTab(tab)}
                                >
                                    <TypographyP
                                        className={cn(
                                            'text-muted-foreground',
                                            isSelected &&
                                                'text-primary-700 dark:text-primary'
                                        )}
                                    >
                                        {tab.label}
                                    </TypographyP>
                                </div>
                            )
                        })}
                    </div>
                    <div className="flex-1 p-2 overflow-auto">
                        {selectedTab.component}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default HistoryDetailDialog
