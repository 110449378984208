import { Outlet } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { validateUserToken } from '@/api/auth/user'
import { getItem } from '@/utils/storage'
import { useEffect } from 'react'
import LoadingScreen from '@/pages/LoadingScreen'
import { isAxiosError } from '@/api/business'
// import { useCheckMobile } from '@/hooks/useCheckMobile'
import { useBaseStore } from '@/store'
import { authSliceActionsSelector } from '@/store/authSlice'

const AppLayout = () => {
    const token = getItem(localStorage, 'token')

    const { login, logout } = useBaseStore(authSliceActionsSelector)

    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation({
        mutationFn: validateUserToken,
        onSuccess: (data) => {
            const token = getItem(localStorage, 'token')

            if (token) {
                login({
                    token: token,
                    user: data.token.usuario.nome,
                    userId: null,
                })
            }
        },

        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    logout(queryClient)
                }
            }
        },
    })

    useEffect(() => {
        if (token) {
            mutate()
        }
    }, [])

    if (isLoading) return <LoadingScreen />

    return <Outlet />
}

export default AppLayout
