import { useFormContext } from 'react-hook-form'

import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { useCompanyQuery } from '@/queries/useCompanyQuery'
import { AddProductSchema } from '@/pages/DadosMestre/ProductDadosMestre/schemas/products'

const CompanySelect = () => {
    const { data: companies = [], isLoading, isError } = useCompanyQuery()
    const { control } = useFormContext<AddProductSchema>()

    return (
        <FormField
            name="SK_EMPRESA"
            control={control}
            render={({ field }) => {
                return (
                    <FormItem>
                        <FormLabel>Empresa</FormLabel>
                        <FormControl>
                            <Select
                                disabled={isLoading || isError}
                                onValueChange={field.onChange}
                                value={field.value}
                            >
                                <SelectTrigger>
                                    <SelectValue
                                        placeholder={
                                            isLoading || isError
                                                ? 'Carregando...'
                                                : 'Selecione uma empresa'
                                        }
                                    />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {companies.map((company) => {
                                            return (
                                                <SelectItem
                                                    key={company.SK_EMPRESA}
                                                    value={company.SK_EMPRESA}
                                                >
                                                    {
                                                        company.ABREVIATURA_EMPRESA
                                                    }
                                                </SelectItem>
                                            )
                                        })}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
            }}
        />
    )
}

export default CompanySelect
