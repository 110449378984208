import ConfirmDialog from '@/components/ConfirmDialog'
import { Row } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { isAxiosError } from '@/api/business'
import { ProductStructureFlat } from '@/types/Product'
import { deleteProductStructure } from '@/api/business/productStructure'
import { productStructureKeys } from '@/queries/useProductStructureQuery'
import { ProductStructureTableData } from '../TreeTable'
import { structureStatusKeys } from '@/queries/useStructureStatusQuery'
import { costHistoryKeys } from '@/queries/useHistoryQuery'

interface DeleteDialogProps {
    row: Row<ProductStructureFlat | ProductStructureTableData>
    isOpen: boolean
    onClose: () => void
}

const DeleteDialog = ({ row, ...props }: DeleteDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate: mutateDelete, isLoading: mutateLoading } = useMutation({
        mutationFn: deleteProductStructure,
        onSuccess: () => {
            queryClient.invalidateQueries(productStructureKeys.lists())
            queryClient.invalidateQueries(structureStatusKeys.all)
            queryClient.invalidateQueries(costHistoryKeys.all)

            props.onClose()
            toast({
                title: 'Componente removido com sucesso',
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro remover componente',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    return (
        <ConfirmDialog
            title="Excluir volume de produção"
            dialogType="destructive"
            isLoading={mutateLoading}
            onConfirm={() =>
                row.original.SK_ESTRUTURA_DE_PRODUTO &&
                mutateDelete({
                    SK_ESTRUTURA_DE_PRODUTO:
                        row.original.SK_ESTRUTURA_DE_PRODUTO,
                })
            }
            {...props}
        >
            <div className="pt-4">
                <p>
                    Realmente deseja excluir o componente{' '}
                    <span className="font-bold">
                        {row.original.PRODUTO_ESTRUTURA}
                    </span>
                    ?
                </p>
            </div>
        </ConfirmDialog>
    )
}

export default DeleteDialog
