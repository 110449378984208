import { useToast } from '@/components/ui/use-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useExtraCostSpreadsheet } from '../../useExtraCostSpreadsheet'
import { createColumnHelper } from '@tanstack/react-table'
import { TableCustomData } from '@/components/Table/type'
import useBulkDialog, { BulkDialogActionNames } from './useBulkDialog'
import { ChangeEvent, useMemo } from 'react'
import { CreateBulkExtraCostProps } from '@/api/business/extraCost/types'
import Select from '@/components/Select'
import { createBulkExtraCost } from '@/api/business/extraCost'
import { extraCostKeys } from '@/queries/useExtraCostQuery'
import {
    ArrowUpToLine,
    Download,
    Info,
    ListPlus,
    Paintbrush,
    X,
} from 'lucide-react'
import useDisclosure from '@/hooks/useDisclosure'
import { isAxiosError } from '@/api/business'
import { cn } from '@/lib/utils'
import ResponseDialog from './components/ResponseDialog'
import { ExtraCostValueCategory } from '@/types/Costs'
import Table from '@/components/Table'
import { Button } from '@/components/ui/button'
import { TypographyP } from '@/components/ui/typography'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { structureStatusKeys } from '@/queries/useStructureStatusQuery'
import { costHistoryKeys } from '@/queries/useHistoryQuery'
interface BulkDialogProps {
    isOpen: boolean
    onClose: () => void
}

export type BulkData = CreateBulkExtraCostProps & {
    STATUS?: number
    MENSAGEM?: string
    DS_PRODUTO_APONTADO?: string
    DS_PRODUTO?: string
    id: string
}

type BulkTable = BulkData & TableCustomData<BulkData>

const columnHelper = createColumnHelper<BulkData>()

const BulkDialog = ({ isOpen, onClose }: BulkDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const { tableData, feedbackResponse, productsLoading, dispatch } =
        useBulkDialog()
    const { onDownloadBulkModel, onReadData } = useExtraCostSpreadsheet()

    const {
        isOpen: isFeedbackDialogOpen,
        onClose: onFeedbackDialogClose,
        onOpen: onFeedbackDialogOpen,
    } = useDisclosure()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: createBulkExtraCost,
        onSuccess: (data) => {
            queryClient.invalidateQueries(extraCostKeys.lists())
            queryClient.invalidateQueries(structureStatusKeys.all)
            queryClient.invalidateQueries(costHistoryKeys.all)

            dispatch({
                type: BulkDialogActionNames.SET_DATA_STATUS,
                payload: data,
            })
            dispatch({
                type: BulkDialogActionNames.RESET,
            })
            onFeedbackDialogOpen()
            toast({
                title: 'Carga realizada com sucesso',
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao realizar carga',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
    const dataMemo = useMemo(() => tableData, [tableData])
    const columnsMemo = useMemo(
        () => [
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    return (
                        <div className="flex items-center justify-center h-full p-0 text-red-500">
                            <button
                                onClick={() =>
                                    dispatch({
                                        type: BulkDialogActionNames.REMOVE_ROW,
                                        payload: { id: row.original.id },
                                    })
                                }
                            >
                                <X size={16} />
                            </button>
                        </div>
                    )
                },
                size: 35,
                enableResizing: false,
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Cod Empresa',
                size: 140,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('SK_PRODUTO_ESTRUTURA', {
                id: 'SK_PRODUTO_ESTRUTURA',
                header: 'Cod Produto apontado',
                size: 170,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('DS_PRODUTO_APONTADO', {
                id: 'DS_PRODUTO_APONTADO',
                header: 'Produto apontado',
                cell: ({ getValue }) => {
                    return (
                        <div
                            className={cn(
                                'p-1 text-xs',
                                !getValue() && 'text-red-500'
                            )}
                        >
                            {getValue() ? getValue() : 'Produto não encontrado'}
                        </div>
                    )
                },
                size: 200,
            }),
            columnHelper.accessor('SK_PRODUTO_COMPONENTE', {
                id: 'SK_PRODUTO_COMPONENTE',
                header: 'Cod Componente',
                size: 170,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                cell: ({ getValue }) => {
                    return (
                        <div
                            className={cn(
                                'p-1 text-xs',
                                !getValue() && 'text-red-500'
                            )}
                        >
                            {getValue() ? getValue() : 'Produto não encontrado'}
                        </div>
                    )
                },
                meta: {
                    enableColumnEditable: true,
                },
                size: 200,
            }),
            columnHelper.accessor('SK_TEMPO', {
                id: 'SK_TEMPO',
                header: 'Data',
                meta: {
                    enableColumnEditable: true,
                },
                size: 90,
            }),
            columnHelper.accessor('DS_CUSTO', {
                id: 'DS_CUSTO',
                header: 'Descrição',
                meta: {
                    enableColumnEditable: true,
                },
                size: 140,
            }),
            columnHelper.accessor('VL_CUSTO_ORCADO', {
                id: 'VL_CUSTO_ORCADO',
                header: 'Custo',
                meta: {
                    enableColumnEditable: true,
                },
                size: 100,
            }),
            columnHelper.accessor('CATEGORIA_VALOR', {
                id: 'CATEGORIA_VALOR',
                header: 'Categoria',
                cell: ({ getValue, column, row }) => {
                    const options: {
                        label: string
                        value: ExtraCostValueCategory
                    }[] = [
                        {
                            label: 'FIXO',
                            value: 'FIXO',
                        },
                        { label: 'VARIAVEL', value: 'VARIAVEL' },
                    ]

                    return (
                        <Select
                            className="px-1 py-0 text-xs rounded-sm"
                            onChange={(value) =>
                                dispatch({
                                    type: BulkDialogActionNames.UPDATE_TABLE_DATA,
                                    payload: {
                                        columnId: column.id,
                                        rowIdx: row.index,
                                        value,
                                    },
                                })
                            }
                            options={options}
                            value={getValue() ? getValue().toString() : 'FIXO'}
                        />
                    )
                },
                size: 110,
            }),
            columnHelper.accessor('DD_TIPO_FRETE', {
                id: 'DD_TIPO_FRETE',
                header: 'Frete',
                cell: ({ row, column, getValue }) => {
                    return (
                        <Select
                            onChange={(value) =>
                                dispatch({
                                    type: BulkDialogActionNames.UPDATE_TABLE_DATA,
                                    payload: {
                                        columnId: column.id,
                                        rowIdx: row.index,
                                        value,
                                    },
                                })
                            }
                            options={[
                                { label: 'CIF', value: 'CIF' },
                                { label: 'FOB', value: 'FOB' },
                            ]}
                            value={getValue() ? getValue() : 'FOB'}
                            className="h-full px-1 text-xs"
                        />
                    )
                },
                size: 80,
            }),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                size: 400,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('VL_PIS', {
                id: 'VL_PIS',
                header: 'PIS',
                meta: {
                    enableColumnEditable: true,
                },
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_PIS', {
                id: 'VL_ALIQUOTA_PIS',
                header: 'PIS (%)',
                meta: {
                    enableColumnEditable: true,
                },
                size: 100,
            }),
            columnHelper.accessor('VL_COFINS', {
                id: 'VL_COFINS',
                header: 'COFINS',
                meta: {
                    enableColumnEditable: true,
                },
                size: 100,
            }),
            columnHelper.accessor('VL_ALIQUOTA_COFINS', {
                id: 'VL_ALIQUOTA_COFINS',
                header: 'COFINS (%)',
                meta: {
                    enableColumnEditable: true,
                },
                size: 120,
            }),
            columnHelper.accessor('VL_FCP', {
                id: 'VL_FCP',
                header: 'FCP',
                meta: {
                    enableColumnEditable: true,
                },
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_FCP', {
                id: 'VL_ALIQUOTA_FCP',
                header: 'FCP (%)',
                meta: {
                    enableColumnEditable: true,
                },
                size: 100,
            }),
            columnHelper.accessor('VL_ICMS', {
                id: 'VL_ICMS',
                header: 'ICMS',
                meta: {
                    enableColumnEditable: true,
                },
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_ICMS', {
                id: 'VL_ALIQUOTA_ICMS',
                header: 'ICMS (%)',
                meta: {
                    enableColumnEditable: true,
                },
                size: 110,
            }),
            columnHelper.accessor('VL_IPI', {
                id: 'VL_IPI',
                header: 'IPI',
                meta: {
                    enableColumnEditable: true,
                },
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_IPI', {
                id: 'VL_ALIQUOTA_IPI',
                header: 'IPI (%)',
                meta: {
                    enableColumnEditable: true,
                },
                size: 100,
            }),
            columnHelper.accessor('VL_ST', {
                id: 'VL_ST',
                header: 'ST',
                meta: {
                    enableColumnEditable: true,
                },
                size: 80,
            }),
            columnHelper.accessor('VL_ALIQUOTA_ST', {
                id: 'VL_ALIQUOTA_ST',
                header: 'ST (%)',
                meta: {
                    enableColumnEditable: true,
                },
                size: 100,
            }),
        ],
        []
    )

    const onSubmit = () => {
        mutate(
            tableData.map((data) => ({
                ...data,
                SK_PRODUTO_COMPONENTE: data.SK_PRODUTO_COMPONENTE
                    ? `${data.SK_EMPRESA}${data.SK_PRODUTO_COMPONENTE}`
                    : null,
                SK_PRODUTO_ESTRUTURA: data.SK_PRODUTO_ESTRUTURA
                    ? `${data.SK_EMPRESA}${data.SK_PRODUTO_ESTRUTURA}`
                    : null,
            }))
        )
    }

    const onChangeFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files
        if (file && file[0]) {
            const data = await onReadData(file[0])
            dispatch({
                type: BulkDialogActionNames.SET_TABLE_DATA,
                payload: data,
            })
        }
    }

    return (
        <>
            <Dialog open={isOpen} onOpenChange={onClose}>
                <DialogContent className="flex flex-col h-full max-w-full rounded-none">
                    <DialogHeader>
                        <DialogTitle className="m-0">
                            Carga custo extra
                        </DialogTitle>
                        <div className="flex items-center justify-end gap-2">
                            {dataMemo.length > 0 && (
                                <Button
                                    className="flex items-center justify-center w-10 h-10 p-0 bg-indigo-500 rounded-md"
                                    onClick={() =>
                                        dispatch({
                                            type: BulkDialogActionNames.ADD_ROW,
                                        })
                                    }
                                >
                                    <ListPlus size={16} />
                                </Button>
                            )}
                            <Button
                                className="gap-2"
                                variant="outline"
                                onClick={() =>
                                    dispatch({
                                        type: BulkDialogActionNames.RESET,
                                    })
                                }
                            >
                                <Paintbrush
                                    size={16}
                                    className="text-amber-500"
                                />
                                Resetar
                            </Button>
                            <Button
                                disabled={productsLoading}
                                asChild
                                variant="outline"
                                className="gap-2"
                            >
                                <label
                                    className="cursor-pointer"
                                    htmlFor="file"
                                    onClick={(e) => {
                                        if (productsLoading) {
                                            return e.preventDefault()
                                        }
                                    }}
                                >
                                    <ArrowUpToLine
                                        size={16}
                                        className="text-primary"
                                    />
                                    Importar planilha
                                </label>
                            </Button>
                            <input
                                className="hidden"
                                id="file"
                                type="file"
                                onChange={onChangeFileInput}
                            />
                            <Button
                                className="gap-2"
                                onClick={() => onDownloadBulkModel(tableData)}
                            >
                                <Download size={16} />
                                Download
                            </Button>
                        </div>
                    </DialogHeader>
                    <div className="flex flex-col flex-1 w-full space-y-4 overflow-auto">
                        <div className="flex-1 w-full overflow-hidden">
                            {dataMemo.length > 0 && columnsMemo ? (
                                <Table<BulkTable>
                                    data={dataMemo}
                                    columns={columnsMemo}
                                    meta={{
                                        cell: {
                                            className: 'px-0 py-0 items-center',
                                        },
                                        updateData: (
                                            rowIdx,
                                            columnId,
                                            value
                                        ) => {
                                            dispatch({
                                                type: BulkDialogActionNames.UPDATE_TABLE_DATA,
                                                payload: {
                                                    rowIdx: Number(rowIdx),
                                                    columnId,
                                                    value,
                                                },
                                            })
                                        },
                                    }}
                                    // getTableInstance={(table) =>
                                    //     setBulkTableInstance(table)
                                    // }
                                />
                            ) : (
                                <div className="flex justify-center w-full">
                                    <TypographyP>
                                        Nenhum dado importado
                                    </TypographyP>
                                </div>
                            )}
                        </div>
                    </div>
                    {dataMemo.some((item) => !item.SK_EMPRESA) && (
                        <div className="flex items-center self-end gap-2 text-orange-500">
                            <Info size={14} />
                            <TypographyP>
                                Há itens com dados faltantes
                            </TypographyP>
                        </div>
                    )}
                    <DialogFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button disabled={mutateLoading} onClick={onSubmit}>
                            Confirmar
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            {isFeedbackDialogOpen && (
                <ResponseDialog
                    isOpen={isFeedbackDialogOpen}
                    onClose={onFeedbackDialogClose}
                    response={feedbackResponse}
                />
            )}
        </>
    )
}

export default BulkDialog
