import { isAxiosError } from '@/api/business'
import {
    fetchStdCost,
    fetchStdCostFreezeLog,
    fetchStdCostUpdateDate,
} from '@/api/business/stdCost'
import { FetchStdCostFreezeLogProps } from '@/api/business/stdCost/type'
import { useToast } from '@/components/ui/use-toast'
import {
    ComponentAPIResponse,
    ComponentCustomNormalized,
    ProductAPIResponse,
    ProductCustomNormalized,
} from '@/types/StandardCost'
import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

export const stdCostKeys = {
    all: ['stdCost'] as const,
    lists: () => [...stdCostKeys.all, 'list'] as const,
    list: (date: number, isAnualAvg: boolean) =>
        [...stdCostKeys.lists(), date, isAnualAvg] as const,
    details: () => [...stdCostKeys.all, 'detail'] as const,
    detail: (params: string) => [...stdCostKeys.details(), params] as const,
    freeze: (params: string) =>
        [...stdCostKeys.details(), 'freeze', params] as const,
}

export interface UseStdCostQueryProps {
    date: number
    isAnualAvg: boolean
    enabled?: boolean
}

interface UseStdCostSelectQuery {
    products: ProductCustomNormalized
    components: ComponentCustomNormalized
}

interface ComponentWithId extends ComponentAPIResponse {
    _id: string
    _parentId: string
}

interface SubComponentsWithIds extends Omit<ComponentWithId, 'COMPONENTES'> {
    COMPONENTES: ComponentWithId[]
}
export const useStdCostsQuery = (props: UseStdCostQueryProps) => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () =>
            fetchStdCost({
                SK_TEMPO: props.date,
                VISAO_MEDIA_ANUAL: props.isAnualAvg,
            }),
        queryKey: stdCostKeys.list(props.date, props.isAnualAvg),
        staleTime: 60 * 60 * 1000,
        enabled: props.enabled,
        keepPreviousData: true,
        select: useCallback(
            (data: ProductAPIResponse[]): UseStdCostSelectQuery => {
                const getComponent = (
                    comp: ComponentAPIResponse | SubComponentsWithIds,
                    parentId: string
                ): SubComponentsWithIds => {
                    const dataOrigin =
                        comp.DD_ORIGEM_DADO || comp.DD_ORIGEM || ''

                    const _id = `${parentId}${comp.SK_PRODUTO_COMPONENTE}${dataOrigin}`

                    const componentWithIds: ComponentWithId = {
                        ...comp,
                        _parentId: parentId,
                        _id: _id,
                    }

                    if (comp.COMPONENTES.length > 0) {
                        // eslint-disable-next-line
                        ;(componentWithIds.COMPONENTES as ComponentWithId[]) = (
                            comp.COMPONENTES as ComponentWithId[]
                        ).flatMap((c) => getComponent(c, _id))
                    }

                    return componentWithIds as unknown as SubComponentsWithIds
                }

                const initialState: UseStdCostSelectQuery = {
                    components: {
                        entities: {},
                        ids: [],
                    },
                    products: {
                        entities: {},
                        ids: [],
                    },
                }

                const products = data.reduce((acc, product) => {
                    const productId =
                        `${product.NK_ESTRUTURA_DE_PRODUTO}${product.DD_ORIGEM_DADO}`.replace(
                            / /g,
                            ''
                        )

                    const products: ProductCustomNormalized = {
                        entities: {
                            ...acc.products.entities,
                            [productId]: {
                                ...product,
                                _id: productId,
                                _parentId: null,
                                VALOR_CUSTO_REAL_COMPONENTE: 0,
                                COMPONENTES: product.COMPONENTES.map(
                                    (component) => {
                                        const dataOrigin =
                                            component.DD_ORIGEM_DADO ||
                                            component.DD_ORIGEM ||
                                            ''

                                        return `${productId}${component.SK_PRODUTO_COMPONENTE}${dataOrigin}`
                                    }
                                ),
                                PRODUTO_FILTRO: `${product.PRODUTO_ESTRUTURA} (${product.NK_PRODUTO_ESTRUTURA})`,
                                CUSTO_PADRAO_UNITARIO: 0,
                                QTD_COMPONENTE: 0,
                                CUSTO_TOTAL_REAL: 0,
                                productUsedAsComponentId: null,
                                CUSTO_SELECIONADO: 0,
                                CUSTO_PADRAO_REAL: 0,
                                CUSTO_PADRAO_TOTAL: 0,
                                CUSTO_PADRAO_TOTAL_REAL: 0,
                                VARIACAO_CUSTO_PADRAO_TOTAL: 0,
                                VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL: 0,
                                VARIACAO_CUSTO_REAL_TOTAL: 0,
                                VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL: 0,
                                VARIACAO_INDICE: 0,
                                VARIACAO_PERCENTUAL_INDICE: 0,
                                VARIACAO_QUANTIDADE_TOTAL: 0,
                                VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL: 0,
                                PRODUTO_QTD_SELECIONADA: null,
                                VISAO_SELECIONADA: null,
                                TIPO_VISAO: null,
                                IMPACTO_CONSUMO: 0,
                                IMPACTO_PRECO: 0,
                                VARIACAO_CUSTO_UNITARIO: 0,
                                VARIACAO_CUSTO_UNITARIO_PERCENTUAL: 0,
                                VARIACAO_CUSTO_PADRAO: 0,
                            },
                        },
                        ids: [...acc.products.ids, productId],
                    }

                    const components: ComponentCustomNormalized =
                        product.COMPONENTES.flatMap((component) => {
                            return getComponent(component, productId)
                        }).reduce(
                            (acc, component) => {
                                const filter = `${product.PRODUTO_ESTRUTURA} (${product.NK_PRODUTO_ESTRUTURA}) ${component.PRODUTO_COMPONENTE} (${component.NK_PRODUTO_COMPONENTE})`

                                const common = {
                                    CUSTO_PADRAO_UNITARIO: 0,
                                    CUSTO_TOTAL_REAL: 0,
                                    PRODUTO_FILTRO: filter,
                                    QTD_COMPONENTE: 0,
                                    CUSTO_PADRAO_REAL: 0,
                                    CUSTO_PADRAO_TOTAL: 0,
                                    CUSTO_PADRAO_TOTAL_REAL: 0,
                                    VARIACAO_CUSTO_PADRAO_TOTAL: 0,
                                    VARIACAO_PERCENTUAL_CUSTO_PADRAO_TOTAL: 0,
                                    VARIACAO_CUSTO_REAL_TOTAL: 0,
                                    VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL: 0,
                                    VARIACAO_INDICE: 0,
                                    VARIACAO_PERCENTUAL_INDICE: 0,
                                    PRODUTO_QTD_SELECIONADA: null,
                                    VARIACAO_QUANTIDADE_TOTAL: 0,
                                    VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL: 0,
                                    IMPACTO_CONSUMO: 0,
                                    IMPACTO_PRECO: 0,
                                    VARIACAO_CUSTO_UNITARIO: 0,
                                    VARIACAO_CUSTO_UNITARIO_PERCENTUAL: 0,
                                    VARIACAO_CUSTO_PADRAO: 0,
                                }
                                const flattenedComponentIds =
                                    component.COMPONENTES
                                        ? component.COMPONENTES.flatMap(
                                              (comp) => comp._id
                                          )
                                        : []

                                const entities = {
                                    ...acc.entities,
                                    [component._id]: {
                                        ...component,
                                        ...common,
                                        SK_EMPRESA: product.SK_EMPRESA,
                                        COMPONENTES: flattenedComponentIds,
                                    },
                                    ...(component.COMPONENTES || []).reduce(
                                        (nestedEntities, nestedComp) => ({
                                            ...nestedEntities,
                                            [nestedComp._id]: {
                                                ...nestedComp,
                                                ...common,
                                                PRODUTO_FILTRO: `${filter} ${nestedComp.PRODUTO_COMPONENTE} (${nestedComp.NK_PRODUTO_COMPONENTE})`,
                                                SK_EMPRESA: product.SK_EMPRESA,
                                                DD_ORIGEM_DADO:
                                                    nestedComp.DD_ORIGEM_DADO ||
                                                    product.DD_ORIGEM_DADO,
                                            },
                                        }),
                                        {}
                                    ),
                                }

                                return {
                                    entities,
                                    ids: [
                                        ...acc.ids,
                                        component._id,
                                        ...flattenedComponentIds,
                                    ],
                                }
                            },
                            {
                                entities: {},
                                ids: [],
                            } as ComponentCustomNormalized
                        )

                    return {
                        products,
                        components: {
                            entities: {
                                ...acc.components.entities,
                                ...components.entities,
                            },
                            ids: [...acc.components.ids, ...components.ids],
                        },
                    }
                }, initialState)
                return products
            },
            []
        ),
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar a visão geral do custo padrão. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}

export const useStdCostUpdateDate = () => {
    return useQuery({
        queryFn: () => fetchStdCostUpdateDate(),
        queryKey: stdCostKeys.detail('updatedAt'),
        staleTime: 60 * 60 * 1000,
    })
}

type UseStdCostFreeeLogProps = FetchStdCostFreezeLogProps

export const useStdCostFreezeLog = ({ date }: UseStdCostFreeeLogProps) => {
    return useQuery({
        queryFn: () => fetchStdCostFreezeLog({ date }),
        queryKey: stdCostKeys.freeze(date.toString()),
        staleTime: 60 * 60 * 1000,
    })
}
