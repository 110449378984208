import { isAxiosError } from '@/api/business'
import {
    fetchStructureStatus,
    fetchStructureStatusById,
} from '@/api/business/structureStatus'
import { StructureStatus } from '@/api/business/structureStatus/type'
import { useToast } from '@/components/ui/use-toast'
import { useQuery } from '@tanstack/react-query'

export const structureStatusKeys = {
    all: ['structureStatus'] as const,
    lists: () => [...structureStatusKeys.all, 'list'] as const,
    list: (initial: number, end: number) =>
        [...structureStatusKeys.lists(), initial, end] as const,
    details: () => [...structureStatusKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...structureStatusKeys.details(), params] as const,
}

interface StructureStatusQuery {
    onSuccess?: (data: StructureStatus[]) => void
}

type StructureStatusQueryByIdProps = {
    id: string
}

export const useStructureStatusQuery = (props?: StructureStatusQuery) => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchStructureStatus(),
        queryKey: structureStatusKeys.lists(),
        onSuccess: props?.onSuccess,
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar as estruturas. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
export const useStructureStatusByIdQuery = ({
    id,
}: StructureStatusQueryByIdProps) => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchStructureStatusById({ id }),
        queryKey: structureStatusKeys.detail(id),
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar as estruturas. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
