import { useToast } from '@/components/ui/use-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from '@/api/business'
import { createProduct } from '@/api/business/product'
import { productKeys } from '@/queries/useProductQuery'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { AddProductSchema, addProductSchema } from '../../schemas/products'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import UMSelect from '../UMSelect'
import ClassificationSelect from '../ClassificationSelect'
import { useEffect } from 'react'
import CompanySelect from './components/CompanySelect'

interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
}

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: createProduct,
        onSuccess: () => {
            queryClient.invalidateQueries(productKeys.lists())
            onClose()
            toast({
                title: 'Produto adicionado com sucesso',
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao criar produto',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const form = useForm<AddProductSchema>({
        resolver: zodResolver(addProductSchema),
        defaultValues: {
            DD_CLASSIFICACAO: '',
            DD_TAMANHO_EMBALAGEM: '0',
            DD_UM: '',
            DS_PRODUTO: '',
            QTD_PRODUTO_POR_EMBALAGEM: '0',
            QTD_VOLUME: '0',
            SK_EMPRESA: '',
        },
    })

    const onSubmit = (data: AddProductSchema) => {
        mutate([
            {
                ...data,
                QTD_PRODUTO_POR_EMBALAGEM: Number(
                    data.QTD_PRODUTO_POR_EMBALAGEM
                ),
                QTD_VOLUME: Number(data.QTD_VOLUME),
                DD_TAMANHO_EMBALAGEM: Number(data.DD_TAMANHO_EMBALAGEM),
            },
        ])
    }

    useEffect(() => {
        const subscription = form.watch((value, { name }) => {
            if (
                name === 'QTD_PRODUTO_POR_EMBALAGEM' ||
                name === 'DD_TAMANHO_EMBALAGEM'
            ) {
                const { QTD_PRODUTO_POR_EMBALAGEM, DD_TAMANHO_EMBALAGEM } =
                    value

                if (QTD_PRODUTO_POR_EMBALAGEM && DD_TAMANHO_EMBALAGEM)
                    form.setValue(
                        'QTD_VOLUME',
                        (
                            Number(QTD_PRODUTO_POR_EMBALAGEM) *
                            Number(DD_TAMANHO_EMBALAGEM)
                        ).toString()
                    )
            }
        })

        return () => subscription.unsubscribe()
    }, [form.watch])

    useEffect(() => {
        if (!isOpen) {
            form.reset()
        }
    }, [isOpen])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-h-full overflow-auto">
                <DialogHeader>
                    <DialogTitle>Criar produto</DialogTitle>
                    <DialogDescription>
                        Insira as informações do novo produto
                    </DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <FormField
                            name="DS_PRODUTO"
                            control={form.control}
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Produto</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <CompanySelect />
                        <UMSelect />
                        <ClassificationSelect />
                        <FormField
                            name="DD_TAMANHO_EMBALAGEM"
                            control={form.control}
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Tamanho embalagem</FormLabel>
                                        <FormControl>
                                            <Input type="number" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <FormField
                            name="QTD_PRODUTO_POR_EMBALAGEM"
                            control={form.control}
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>
                                            Qtd produto por embalagem
                                        </FormLabel>
                                        <FormControl>
                                            <Input type="number" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <FormField
                            name="QTD_VOLUME"
                            control={form.control}
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Volume (ml)</FormLabel>
                                        <FormControl>
                                            <Input type="number" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <div className="flex justify-end gap-2">
                            <Button
                                variant="ghost"
                                type="button"
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            <Button disabled={mutateLoading}>Confirmar</Button>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AddDialog
