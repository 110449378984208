import instance from '..'
import { Company } from './types'

const BASE_ROUTE = '/empresa/portalcusto/empresa'

export const fetchCurrencies = async () => {
    const { data } = await instance.post<Company[]>(BASE_ROUTE)

    return data
}
