import { Table } from '@tanstack/react-table'
import { utils, writeFile } from 'xlsx'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'
import {
    Component,
    ComponentCustomEntity,
    CostView,
    DataOrigin,
    Product,
    ProductCustomEntity,
    StandardCostCustomData,
    StandardCostTableData,
} from '@/types/StandardCost'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { useCallback } from 'react'
import { statusLocale } from '@/types/Status'

export type SheetData = Partial<
    Pick<
        Product,
        | 'SK_PRODUTO_ESTRUTURA'
        | 'SK_EMPRESA'
        | 'ABREVIATURA_EMPRESA'
        | 'SK_PRODUTO_ESTRUTURA'
        | 'NK_PRODUTO_ESTRUTURA'
        | 'PRODUTO_ESTRUTURA'
        | 'DS_REVISAO_FINAL'
        | 'DS_REVISAO_INICIAL'
        | 'DD_CLASSIFICACAO'
        | 'UM_PRODUTO_ESTRUTURA'
        | 'VALOR_INFORMADO_PRODUTO'
        | 'DD_ORIGEM_DADO'
        | 'PRODUTO_APONTADO_BLOQUEADO'
        | 'VARIACAO_INDICE'
        | 'VARIACAO_PERCENTUAL_INDICE'
    >
> &
    Partial<
        Pick<
            Component,
            | 'PRODUTO_COMPONENTE'
            | 'SK_PRODUTO_COMPONENTE'
            | 'NK_PRODUTO_COMPONENTE'
            | 'ULTIMO_PRECO'
            | 'ULTIMO_PRECO_UNITARIO'
            | 'VALOR_CUSTO_REAL_COMPONENTE'
            | 'VALOR_CUSTO_REAL_UNITARIO'
            | 'INDICE_STANDARD'
            | 'INDICE_COMPONENTE_REAL'
            | 'QTD_REAL_UTILIZADA'
            | 'VALOR_INFORMADO_COMPONENTE'
            | 'DD_VARIANCIA_MAX'
            | 'DD_VARIANCIA_MIN'
        >
    > &
    StandardCostCustomData & { CUSTO_EXTRA: string }

type Sums = {
    CUSTO_MATERIA_PRIMA: number
    DESPESAS: number
    CUSTO_TOTAL: number
}

type SumReturn = Sums &
    Pick<
        Product,
        | 'PRODUTO_ESTRUTURA'
        | 'SK_EMPRESA'
        | 'NK_PRODUTO_ESTRUTURA'
        | 'ABREVIATURA_EMPRESA'
        | 'CENARIO_CUSTO_EXTRA'
        | 'CENARIO_CUSTO_INFORMADO'
        | 'CENARIO_ESTRUTURA'
    >

const HEADERS: {
    original: keyof SheetData | 'CUSTO_EXTRA'
    header: string
}[] = [
    { original: 'NK_PRODUTO_ESTRUTURA', header: 'COD PRODUTO' },
    { original: 'PRODUTO_ESTRUTURA', header: 'PRODUTO' },
    { original: 'NK_PRODUTO_COMPONENTE', header: 'COD COMPONENTE' },
    { original: 'PRODUTO_COMPONENTE', header: 'COMPONENTE' },
    { original: 'SK_EMPRESA', header: 'COD EMPRESA' },
    { original: 'ABREVIATURA_EMPRESA', header: 'EMPRESA' },
    { original: 'UM_PRODUTO_ESTRUTURA', header: 'UM' },
    { original: 'CUSTO_SELECIONADO', header: 'CUSTO PADRÃO' },
    { original: 'CUSTO_PADRAO_REAL', header: 'CUSTO PADRÃO REAL' },
    { original: 'VALOR_CUSTO_REAL_COMPONENTE', header: 'CUSTO REAL' },
    { original: 'VARIACAO_CUSTO_PADRAO', header: 'VAR CUSTO PADRÃO' },
    { original: 'CUSTO_PADRAO_TOTAL', header: 'CUSTO PADRÃO TOTAL' },
    { original: 'CUSTO_PADRAO_TOTAL_REAL', header: 'CUSTO PADRÃO REAL TOTAL' },
    { original: 'CUSTO_TOTAL_REAL', header: 'CUSTO REAL TOTAL' },
    { original: 'VARIACAO_INDICE', header: 'VAR INDICE' },
    { original: 'VARIACAO_PERCENTUAL_INDICE', header: 'VAR INDICE (%)' },
    {
        original: 'VARIACAO_CUSTO_PADRAO_TOTAL',
        header: 'VAR CUSTO PADRAO TOTAL',
    },
    {
        original: 'VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL',
        header: 'VAR CUSTO PADRAO REAL TOTAL (%)',
    },
    { original: 'VARIACAO_CUSTO_REAL_TOTAL', header: 'VAR CUSTO REAL TOTAL' },
    {
        original: 'VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL',
        header: 'VAR CUSTO REAL TOTAL (%)',
    },
    { original: 'VARIACAO_QUANTIDADE_TOTAL', header: 'VAR QTDE TOTAL' },
    {
        original: 'VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL',
        header: 'VAR QTDE TOTAL (%)',
    },
    { original: 'ULTIMO_PRECO', header: 'ÚLTIMO PREÇO' },
    { original: 'QTD_COMPONENTE', header: 'QTDE' },
    { original: 'VISAO_SELECIONADA', header: 'VISÃO' },
    { original: 'DD_ORIGEM_DADO', header: 'ORIGEM DO DADO' },
    { original: 'VALOR_INFORMADO_COMPONENTE', header: 'CUSTO INFORMADO' },
    { original: 'CUSTO_PADRAO_UNITARIO', header: 'CUSTO UNITÁRIO STD' },
    { original: 'VALOR_CUSTO_REAL_UNITARIO', header: 'CUSTO UNITÁRIO REAL' },
    { original: 'VARIACAO_CUSTO_UNITARIO', header: 'VAR CUSTO UNITÁRIO' },
    {
        original: 'VARIACAO_CUSTO_UNITARIO_PERCENTUAL',
        header: 'VAR CUSTO UNITÁRIO (%)',
    },
    { original: 'IMPACTO_CONSUMO', header: 'IMPACTO CONSUMO' },
    { original: 'IMPACTO_PRECO', header: 'IMPACTO PREÇO' },
    { original: 'INDICE_STANDARD', header: 'INDICE STD' },
    { original: 'INDICE_COMPONENTE_REAL', header: 'INDICE REAL' },
    { original: 'VARIACAO_INDICE', header: 'VAR. INDICE' },
    { original: 'VARIACAO_PERCENTUAL_INDICE', header: 'VAR. INDICE (%)' },
    { original: 'DD_VARIANCIA_MIN', header: 'VAR. MIN' },
    { original: 'DD_VARIANCIA_MAX', header: 'VAR. MAX' },
]

const SUM_HEADERS: {
    original: keyof SumReturn
    header: string
}[] = [
    {
        header: 'Cod produto',
        original: 'NK_PRODUTO_ESTRUTURA',
    },
    {
        header: 'Cod empresa',
        original: 'SK_EMPRESA',
    },
    {
        header: 'Empresa',
        original: 'ABREVIATURA_EMPRESA',
    },
    {
        header: 'Produto',
        original: 'PRODUTO_ESTRUTURA',
    },
    {
        header: 'Despesas',
        original: 'DESPESAS',
    },
    {
        header: 'Custo Total',
        original: 'CUSTO_TOTAL',
    },
    {
        header: 'Custo MP',
        original: 'CUSTO_MATERIA_PRIMA',
    },
    {
        header: 'Cenário Estrutura',
        original: 'CENARIO_ESTRUTURA',
    },
    {
        header: 'Custo Custo Informado',
        original: 'CENARIO_CUSTO_INFORMADO',
    },
    {
        header: 'Cenário Custo Extra',
        original: 'CENARIO_CUSTO_EXTRA',
    },
]

const selector = (state: Store) => ({
    stdCostData: state.stdCostSlice.state.stdCostData,
    selectedDate: state.stdCostSlice.state.selectedDate,
    selectedCompany: state.stdCostSlice.state.stdCostSelectedCompany,
})

const origin = {
    PROTHEUS: 'Protheus',
    MANUAL: 'Manual',
    FORA_DE_ESTRUTURA: 'Fora de Estrutura',
    CUSTO_EXTRA: 'Custo Extra',
}

export const useStdCostSpreadsheet = () => {
    const {
        stdCostData: stdCost,
        selectedDate: date,
        selectedCompany,
    } = useBaseStore(selector)

    const getCommonAttributes = useCallback(
        (component: ComponentCustomEntity) => {
            return {
                SK_PRODUTO_COMPONENTE: component.SK_PRODUTO_COMPONENTE,
                CUSTO_SELECIONADO: component.CUSTO_SELECIONADO,
                CUSTO_PADRAO_REAL: component.CUSTO_PADRAO_REAL || 0,
                VALOR_CUSTO_REAL_COMPONENTE:
                    component.VALOR_CUSTO_REAL_COMPONENTE || 0,
                VARIACAO_CUSTO_PADRAO: component.VARIACAO_CUSTO_PADRAO,
                CUSTO_PADRAO_TOTAL: component.CUSTO_PADRAO_TOTAL || 0,
                CUSTO_PADRAO_TOTAL_REAL: component.CUSTO_PADRAO_TOTAL_REAL || 0,
                VALOR_INFORMADO_PRODUTO:
                    component.VALOR_INFORMADO_COMPONENTE || 0,
                ULTIMO_PRECO: component.ULTIMO_PRECO || 0,
                QTD_COMPONENTE: component.QTD_COMPONENTE || 0,
                VISAO_SELECIONADA: component.VISAO_SELECIONADA,
                DD_ORIGEM_DADO:
                    (origin[component.DD_ORIGEM_DADO] as DataOrigin) ||
                    component.DD_ORIGEM_DADO,
                CUSTO_TOTAL_REAL: component.CUSTO_TOTAL_REAL,
                CUSTO_PADRAO_UNITARIO: component.CUSTO_PADRAO_UNITARIO,
                INDICE_STANDARD: component.INDICE_STANDARD,
                VARIACAO_INDICE: component.VARIACAO_INDICE || 0,
                VARIACAO_PERCENTUAL_INDICE:
                    component.VARIACAO_PERCENTUAL_INDICE || 0,
                VARIACAO_CUSTO_PADRAO_TOTAL:
                    component.VARIACAO_CUSTO_PADRAO_TOTAL || 0,
                VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL:
                    component.VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL || 0,
                VARIACAO_CUSTO_REAL_TOTAL:
                    component.VARIACAO_CUSTO_REAL_TOTAL || 0,
                VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL:
                    component.VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL || 0,
                VARIACAO_QUANTIDADE_TOTAL:
                    component.VARIACAO_QUANTIDADE_TOTAL || 0,
                VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL:
                    component.VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL || 0,
                IMPACTO_CONSUMO: component.IMPACTO_CONSUMO,
                IMPACTO_PRECO: component.IMPACTO_PRECO,
                VALOR_INFORMADO_COMPONENTE:
                    component.VALOR_INFORMADO_COMPONENTE || 0,
                VALOR_CUSTO_REAL_UNITARIO: component.VALOR_CUSTO_REAL_UNITARIO,
                VARIACAO_CUSTO_UNITARIO: component.VARIACAO_CUSTO_UNITARIO,
                VARIACAO_CUSTO_UNITARIO_PERCENTUAL:
                    component.VARIACAO_CUSTO_UNITARIO_PERCENTUAL,
                INDICE_COMPONENTE_REAL:
                    Number(component.INDICE_COMPONENTE_REAL) || 0,
                DD_VARIANCIA_MAX: Number(component.DD_VARIANCIA_MAX) || 0,
                DD_VARIANCIA_MIN: Number(component.DD_VARIANCIA_MIN) || 0,
            }
        },
        []
    )

    const getTableRows = useCallback(
        (groupComponents: boolean = false): Partial<SheetData>[] => {
            const components = Object.values(stdCost.components.entities)

            if (groupComponents) {
                return stdCost.products.ids.flatMap((id) => {
                    const product = stdCost.products.entities[id]

                    return product.COMPONENTES.map((compId) => {
                        const component = stdCost.components.entities[compId]

                        const componentAttr = getCommonAttributes(component)

                        return {
                            SK_PRODUTO_ESTRUTURA: product.SK_PRODUTO_ESTRUTURA,
                            NK_PRODUTO_ESTRUTURA: product.NK_PRODUTO_ESTRUTURA,
                            PRODUTO_ESTRUTURA: product.PRODUTO_ESTRUTURA,
                            NK_PRODUTO_COMPONENTE:
                                component.NK_PRODUTO_COMPONENTE || '',
                            PRODUTO_COMPONENTE: component.PRODUTO_COMPONENTE,
                            CUSTO_EXTRA: component.PRODUTO_COMPONENTE,
                            SK_EMPRESA:
                                product?.SK_EMPRESA || product.SK_EMPRESA,
                            ABREVIATURA_EMPRESA: product?.ABREVIATURA_EMPRESA,
                            UM_PRODUTO_ESTRUTURA: product?.UM_PRODUTO_ESTRUTURA,
                            TIPO_VISAO: component.TIPO_VISAO,
                            ...componentAttr,
                        }
                    })
                })
            }

            return stdCost.components.ids
                .map((id) => {
                    const component = stdCost.components.entities[id]
                    const componentAttr = getCommonAttributes(component)

                    const parent =
                        stdCost.components.entities[component._parentId] ||
                        stdCost.products.entities[component._parentId]

                    if (component.TIPO_VISAO === CostView.CUSTO_EXTRA) {
                        if (parent._parentId) {
                            const grandParent =
                                stdCost.products.entities[parent._parentId]

                            return {
                                SK_PRODUTO_ESTRUTURA:
                                    grandParent.SK_PRODUTO_ESTRUTURA,
                                NK_PRODUTO_ESTRUTURA:
                                    grandParent?.NK_PRODUTO_ESTRUTURA,
                                PRODUTO_ESTRUTURA:
                                    grandParent?.PRODUTO_ESTRUTURA,
                                NK_PRODUTO_COMPONENTE:
                                    parent.NK_PRODUTO_COMPONENTE || '',
                                PRODUTO_COMPONENTE: `${parent.PRODUTO_COMPONENTE} ${component.PRODUTO_COMPONENTE}`,
                                CUSTO_EXTRA: component.PRODUTO_COMPONENTE,
                                SK_EMPRESA:
                                    grandParent?.SK_EMPRESA ||
                                    parent.SK_EMPRESA,
                                ABREVIATURA_EMPRESA:
                                    grandParent?.ABREVIATURA_EMPRESA,
                                UM_PRODUTO_ESTRUTURA:
                                    grandParent?.UM_PRODUTO_ESTRUTURA,
                                ...componentAttr,
                            }
                        } else {
                            const p = parent as unknown as ProductCustomEntity

                            return {
                                SK_PRODUTO_ESTRUTURA: p.SK_PRODUTO_ESTRUTURA,
                                NK_PRODUTO_ESTRUTURA: p.NK_PRODUTO_ESTRUTURA,
                                PRODUTO_ESTRUTURA: p.PRODUTO_ESTRUTURA,
                                NK_PRODUTO_COMPONENTE: '',
                                CUSTO_EXTRA: component.PRODUTO_COMPONENTE,
                                PRODUTO_COMPONENTE:
                                    component.PRODUTO_COMPONENTE,
                                SK_EMPRESA: p.SK_EMPRESA || parent.SK_EMPRESA,
                                ABREVIATURA_EMPRESA: p.ABREVIATURA_EMPRESA,
                                UM_PRODUTO_ESTRUTURA: p.UM_PRODUTO_ESTRUTURA,
                                ...componentAttr,
                            }
                        }
                    } else {
                        const p = parent as unknown as ProductCustomEntity

                        if (p._parentId) {
                            const grandParent =
                                stdCost.products.entities[parent._parentId]

                            return {
                                SK_PRODUTO_ESTRUTURA:
                                    grandParent.SK_PRODUTO_ESTRUTURA,
                                NK_PRODUTO_ESTRUTURA:
                                    grandParent?.NK_PRODUTO_ESTRUTURA,
                                PRODUTO_ESTRUTURA:
                                    grandParent?.PRODUTO_ESTRUTURA,
                                NK_PRODUTO_COMPONENTE:
                                    parent.NK_PRODUTO_COMPONENTE || '',
                                PRODUTO_COMPONENTE: parent.PRODUTO_COMPONENTE,
                                SK_EMPRESA:
                                    grandParent?.SK_EMPRESA ||
                                    parent.SK_EMPRESA,
                                CUSTO_EXTRA: '',
                                ABREVIATURA_EMPRESA:
                                    grandParent?.ABREVIATURA_EMPRESA,
                                UM_PRODUTO_ESTRUTURA:
                                    grandParent?.UM_PRODUTO_ESTRUTURA,
                                ...componentAttr,
                            }
                        } else {
                            const children = components.filter(
                                (c) => c._parentId === id
                            )

                            if (children.length > 0) {
                                return false
                            }

                            return {
                                SK_PRODUTO_ESTRUTURA: p.SK_PRODUTO_ESTRUTURA,
                                NK_PRODUTO_ESTRUTURA: p.NK_PRODUTO_ESTRUTURA,
                                PRODUTO_ESTRUTURA: p.PRODUTO_ESTRUTURA,
                                NK_PRODUTO_COMPONENTE:
                                    component.NK_PRODUTO_COMPONENTE || '',
                                PRODUTO_COMPONENTE:
                                    component.PRODUTO_COMPONENTE,
                                CUSTO_EXTRA: '',
                                SK_EMPRESA: parent.SK_EMPRESA,
                                ABREVIATURA_EMPRESA: p.ABREVIATURA_EMPRESA,
                                UM_PRODUTO_ESTRUTURA: p.UM_PRODUTO_ESTRUTURA,
                                ...componentAttr,
                            }
                        }
                    }
                })
                .filter(Boolean) as Partial<SheetData>[]
        },
        [stdCost.components, stdCost.products]
    )

    const getHeaders = (table: Table<StandardCostTableData>): string[] =>
        table
            .getFlatHeaders()
            .map(
                (header) =>
                    HEADERS.find((h) => header.id === h.original)?.header || ''
            )
            .filter((header) => Boolean(header))

    const onDownload = useCallback(() => {
        const data = getTableRows().map((row) => {
            const obj = {}

            for (const [key, value] of Object.entries(row)) {
                const newKey = HEADERS.find((h) => key === h.original)?.header

                if (newKey) {
                    obj[newKey] = value
                }
            }
            return obj
        })

        const worksheet = utils.json_to_sheet(data)

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Custo padrao')

        writeFile(
            workbook,
            `Custo Padrão - ${format(getDateFromId(date.id), 'MMyyyy')}.xlsx`,
            {
                compression: true,
            }
        )
    }, [date.id, stdCost.products, stdCost.components])

    const onDownloadSum = useCallback(() => {
        const result = Object.values(stdCost.products.entities)
            .filter((product) => product.SK_EMPRESA === selectedCompany)
            .map((product) => {
                const children = stdCost.components.ids
                    .filter(
                        (childId) =>
                            stdCost.components.entities[childId]._parentId ===
                            product._id
                    )
                    .map((childId) => stdCost.components.entities[childId])

                const avgSum = children
                    .filter(
                        (child) => child.TIPO_VISAO !== CostView.CUSTO_EXTRA
                    )
                    .reduce<number>((acc, component) => {
                        return (acc += component.CUSTO_SELECIONADO)
                    }, 0)

                const expenses = children
                    .filter(
                        (child) => child.TIPO_VISAO === CostView.CUSTO_EXTRA
                    )
                    .reduce<number>((acc, component) => {
                        return (acc += component.CUSTO_SELECIONADO)
                    }, 0)

                return {
                    ...product,
                    CENARIO_CUSTO_EXTRA:
                        statusLocale[product.CENARIO_CUSTO_EXTRA],
                    CENARIO_CUSTO_INFORMADO:
                        statusLocale[product.CENARIO_CUSTO_INFORMADO],
                    CENARIO_ESTRUTURA: statusLocale[product.CENARIO_ESTRUTURA],
                    CUSTO_MATERIA_PRIMA: avgSum,
                    DESPESAS: expenses,
                    CUSTO_TOTAL: avgSum + expenses,
                }
            })

        const data = result.map((row) => {
            const obj = {}

            for (const [key, value] of Object.entries(row)) {
                const newKey = SUM_HEADERS.find(
                    (h) => key === h.original
                )?.header

                if (newKey) {
                    obj[newKey] = value
                }
            }
            return obj
        })

        const worksheet = utils.json_to_sheet(data)

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Custo padrao')

        writeFile(
            workbook,
            `Custo Padrão Pricing - ${format(
                getDateFromId(date.id),
                'MMyyyy'
            )}.xlsx`,
            {
                compression: true,
            }
        )
    }, [date.id, stdCost.products, stdCost.components, selectedCompany])

    return {
        onDownload,
        getHeaders,
        getTableRows,
        onDownloadSum,
    }
}
