import { useFormContext, useWatch } from 'react-hook-form'
import { AddSchema } from '../..'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import VirtualizedCommand from '@/components/VirtualizedCommand'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useEffect, useMemo } from 'react'
import { Button } from '@/components/ui/button'
import { TypographyP } from '@/components/ui/typography'

const ProductSelect = () => {
    const { control, resetField } = useFormContext<AddSchema>()

    const {
        data: productStructureData = [],
        isLoading,
        isError,
    } = useProductStructureQuery({
        enabled: true,
    })

    const companyField = useWatch({ name: 'companyId' })
    const productField = useWatch({ name: 'productId' })

    const products = useMemo(() => {
        if (companyField) {
            const unique: Record<string, (typeof productStructureData)[0]> = {}

            const filtered = productStructureData.filter(
                (product) => product.SK_EMPRESA.toString() === companyField
            )

            for (const product of filtered) {
                if (!unique[product.SK_PRODUTO_ESTRUTURA]) {
                    unique[product.SK_PRODUTO_ESTRUTURA] = product
                }
            }

            return Object.values(unique)
        } else {
            return []
        }
    }, [productStructureData, companyField])

    useEffect(() => {
        if (!companyField) {
            resetField('productId')
        }
    }, [companyField])

    return (
        <FormField
            name="productId"
            control={control}
            render={({ field }) => {
                let label = 'Selecione um produto'

                if (isLoading) {
                    label = 'Carregando...'
                } else if (isError) {
                    label = 'Erro ao carregar dados'
                } else if (!companyField) {
                    label = 'Selecione uma empresa'
                } else if (!productField) {
                    label = 'Selecione um produto'
                } else if (field.value) {
                    const product = productStructureData.find(
                        (comp) => comp.SK_PRODUTO_ESTRUTURA == field.value
                    )
                    label = product?.PRODUTO_ESTRUTURA || label
                }

                return (
                    <FormItem>
                        <FormControl>
                            <>
                                <FormLabel>Componente estrutura</FormLabel>
                                <Popover>
                                    <PopoverTrigger
                                        asChild
                                        disabled={isLoading || isError}
                                    >
                                        <Button
                                            type="button"
                                            variant="outline"
                                            className="flex justify-start w-full overflow-hidden"
                                        >
                                            <TypographyP className="flex-1 overflow-hidden text-start text-ellipsis">
                                                {label}
                                            </TypographyP>
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent
                                        className="z-50 w-[400px] p-0"
                                        align="start"
                                    >
                                        <VirtualizedCommand
                                            onSelectionChange={(value) =>
                                                field.onChange(value)
                                            }
                                            options={products.map(
                                                (component) => {
                                                    return {
                                                        value: component.SK_PRODUTO_ESTRUTURA,
                                                        label: component.PRODUTO_ESTRUTURA,
                                                    }
                                                }
                                            )}
                                            selectedValues={{
                                                [field.value]: true,
                                            }}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
                // return (
                //     <FormItem>
                //         <FormLabel>Produto estrutura</FormLabel>
                //         <FormControl>
                //             <Select
                //                 onValueChange={field.onChange}
                //                 value={field.value}
                //             >
                //                 <SelectTrigger>
                //                     <SelectValue
                //                         placeholder={
                //                             !companyField
                //                                 ? 'Selecione uma empresa'
                //                                 : 'Selecione um produto'
                //                         }
                //                     />
                //                 </SelectTrigger>
                //                 <SelectContent>
                //                     <SelectGroup>
                //                         {products.map((product) => {
                //                             return (
                //                                 <SelectItem
                //                                     key={
                //                                         product.SK_PRODUTO_ESTRUTURA
                //                                     }
                //                                     value={
                //                                         product.SK_PRODUTO_ESTRUTURA
                //                                     }
                //                                 >
                //                                     {product.PRODUTO_ESTRUTURA}
                //                                 </SelectItem>
                //                             )
                //                         })}
                //                     </SelectGroup>
                //                 </SelectContent>
                //             </Select>
                //         </FormControl>
                //         <FormMessage />
                //     </FormItem>
                // )
            }}
        />
    )
}

export default ProductSelect
