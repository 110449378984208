import { isAxiosError } from '@/api/business'
import { createCostView, updateCostView } from '@/api/business/stdCost'
import {
    CreateCostViewProps,
    UpdateCostViewProps,
} from '@/api/business/stdCost/type'
import ConfirmDialog from '@/components/ConfirmDialog'
import { TypographyP } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import { cn } from '@/lib/utils'
import { costViewKeys } from '@/queries/useCostViewQuery'
import { historyKeys } from '@/queries/useStdCostHistoryQuery'
import { useBaseStore } from '@/store'
import {
    stdCostSliceActionsSelector,
    stdCostSliceStateSelector,
} from '@/store/stdCostSlice'
import { CostViewState } from '@/store/stdCostSlice/type'
import { CostView } from '@/types/Product'
import { ComponentCustomEntity } from '@/types/StandardCost'
import { getDateFromId } from '@/utils/date'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { MoveRight } from 'lucide-react'
import { useCallback, useMemo } from 'react'

interface SaveViewDialogProps {
    isOpen: boolean
    onClose: () => void
}

const SaveViewDialog = ({ isOpen, onClose }: SaveViewDialogProps) => {
    const queryClient = useQueryClient()

    const {
        initialCostViews: initialViews,
        changedViews,
        selectedStdCostRow: selectedRow,
        selectedDate,
        stdCostData: { components, products },
    } = useBaseStore(stdCostSliceStateSelector)

    const { onResetChangedViews } = useBaseStore(stdCostSliceActionsSelector)

    const component = selectedRow && components.entities[selectedRow.id]
    const product = component && products.entities[component._parentId]

    const historyQueryKey = historyKeys.detail({
        productId: product?.SK_PRODUTO_ESTRUTURA || '',
        componentId: component?.SK_PRODUTO_COMPONENTE || '',
        date: selectedDate.id,
    })

    const { toast } = useToast()

    const { mutateAsync: createMutate, isLoading: isCreateLoading } =
        useMutation({
            mutationFn: createCostView,
        })

    const { mutateAsync: updateMutate, isLoading: isUpdateLoading } =
        useMutation({
            mutationFn: updateCostView,
        })

    const changedProducts = useMemo(() => {
        const changed: {
            date: number
            products: (ComponentCustomEntity & {
                VISAO_ORIGINAL: CostView
            })[]
        }[] = []

        for (const [date] of Object.entries(changedViews)) {
            const products: (ComponentCustomEntity & {
                VISAO_ORIGINAL: CostView
            })[] = []

            for (const [productId, costView] of Object.entries(
                changedViews[date]
            )) {
                const componentFound = components.entities[productId]

                if (componentFound) {
                    if (costView.updated !== costView.original) {
                        products.push({
                            ...componentFound,
                            VISAO_SELECIONADA: costView.updated,
                            VISAO_ORIGINAL: costView.original,
                        })
                    }
                }
            }

            if (products.length > 0) {
                changed.push({
                    date: Number(date),
                    products: products,
                })
            }
        }

        return changed
    }, [changedViews])

    const onSaveCostViews = useCallback(async () => {
        const updated: UpdateCostViewProps = {
            VISAO: [],
            HISTORICO: [],
        }
        const created: CreateCostViewProps[] = []

        // let idx = 0
        for (const [date, productsIds] of Object.entries(changedViews)) {
            const productsDate = initialViews.find(
                (view) => view.SK_TEMPO === Number(date)
            )
            if (productsDate) {
                const originalProducts = JSON.parse(
                    productsDate.DS_CONFIGURACAO_EXIBICAO
                ) as CostViewState

                for (const [productId, costView] of Object.entries(
                    changedViews[date]
                )) {
                    if (costView.original !== costView.updated) {
                        const componentFound = components.entities[productId]
                        const parentFound =
                            products.entities[componentFound._parentId] ||
                            components.entities[componentFound._parentId]
                        if (componentFound && parentFound) {
                            if (
                                originalProducts[date] &&
                                originalProducts[date][productId]
                            ) {
                                updated.HISTORICO.push({
                                    DD_VISAO: costView.original,
                                    SK_EMPRESA: parentFound.SK_EMPRESA,
                                    SK_PRODUTO_COMPONENTE:
                                        componentFound.SK_PRODUTO_COMPONENTE as string,
                                    SK_PRODUTO_ESTRUTURA:
                                        parentFound.SK_PRODUTO_ESTRUTURA,
                                    SK_TEMPO: Number(date),
                                })
                            } else {
                                updated.HISTORICO.push({
                                    DD_VISAO: costView.original,
                                    SK_EMPRESA: parentFound.SK_EMPRESA,
                                    SK_PRODUTO_COMPONENTE:
                                        componentFound.SK_PRODUTO_COMPONENTE as string,
                                    SK_PRODUTO_ESTRUTURA:
                                        parentFound.SK_PRODUTO_ESTRUTURA,
                                    SK_TEMPO: Number(date),
                                })
                            }
                        }
                    }
                }
                updated.VISAO.push({
                    IDENTIFICADOR: productsDate.SK_CONFIGURACAO_EXIBICAO,
                    CAMPOS: {
                        DS_CONFIGURACAO_EXIBICAO: JSON.stringify({
                            [date]: {
                                ...originalProducts[date],
                                ...productsIds,
                            },
                        }),
                    },
                })
            } else {
                const view = {}

                for (const [productId, costView] of Object.entries(
                    changedViews[date]
                )) {
                    if (costView.original !== costView.updated) {
                        view[date] = {
                            ...view[date],
                            [productId]: costView,
                        }
                    }
                }

                if (Object.keys(view).length > 0) {
                    created.push({
                        DS_CONFIGURACAO_EXIBICAO: JSON.stringify(view),
                        DD_TIPO_CONFIGURACAO: 'VISAO_CUSTO_PADRAO',
                        NM_CONFIGURACAO_EXIBICAO: '',
                        SK_TEMPO: Number(date),
                    })
                }
            }
            // idx++
        }

        try {
            if (created.length > 0) {
                await createMutate(created)
            }

            if (updated.VISAO.length > 0 && updated.HISTORICO.length > 0) {
                await updateMutate(updated)
            }

            toast({
                title: 'Visão salva com sucesso',
            })
            queryClient.invalidateQueries(costViewKeys.lists())
            queryClient.invalidateQueries(historyQueryKey)
            onResetChangedViews()
            onClose()
        } catch (err) {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro salvar visão',
                        description:
                            'Não foi possível remover o custo, tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        }
    }, [changedViews, initialViews])

    return (
        <ConfirmDialog
            title="Salvar visões"
            className="max-w-4xl overflow-hidden"
            isOpen={isOpen}
            buttonLabel="Salvar"
            buttonDisabled={changedProducts.length === 0}
            isLoading={isCreateLoading || isUpdateLoading}
            onClose={onClose}
            onConfirm={onSaveCostViews}
        >
            <div className="flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                <div className="w-full mb-2">
                    <TypographyP className="font-semibold">
                        Visões alteradas:
                    </TypographyP>
                </div>
                {changedProducts.length > 0 ? (
                    changedProducts.map((date) => {
                        return (
                            <div className="w-full" key={date.date}>
                                <TypographyP className="font-semibold">
                                    {format(
                                        getDateFromId(date.date),
                                        'MM/yyyy'
                                    )}
                                </TypographyP>
                                <div className="w-full border-t border-neutral-300">
                                    {date.products.map((product) => {
                                        const parent =
                                            products.entities[
                                                product._parentId
                                            ] ||
                                            components.entities[
                                                product._parentId
                                            ]

                                        return (
                                            <div
                                                key={product._id}
                                                className="flex items-center gap-4 py-2 border-b border-neutral-300"
                                            >
                                                <TypographyP>
                                                    {parent.SK_EMPRESA} -{' '}
                                                    {
                                                        product?.PRODUTO_COMPONENTE
                                                    }{' '}
                                                    (
                                                    {
                                                        product?.NK_PRODUTO_COMPONENTE
                                                    }
                                                    )
                                                </TypographyP>
                                                <div className="flex items-center gap-4">
                                                    <span
                                                        className={cn(
                                                            'rounded-md text-sm py-1 px-2 whitespace-nowrap',
                                                            product.VISAO_ORIGINAL ===
                                                                CostView.CUSTO_INFORMADO &&
                                                                'text-green-500 bg-green-50',
                                                            product.VISAO_ORIGINAL ===
                                                                CostView.ULTIMO_PRECO &&
                                                                'text-primary-500 bg-primary-50'
                                                        )}
                                                    >
                                                        {product.VISAO_ORIGINAL}
                                                    </span>
                                                    <MoveRight
                                                        strokeWidth={0.8}
                                                    />
                                                    <span
                                                        className={cn(
                                                            'rounded-md text-sm py-1 px-2 whitespace-nowrap',
                                                            product.VISAO_SELECIONADA ===
                                                                CostView.CUSTO_INFORMADO &&
                                                                'text-green-500 bg-green-50',
                                                            product.VISAO_SELECIONADA ===
                                                                CostView.ULTIMO_PRECO &&
                                                                'text-primary-500 bg-primary-50'
                                                        )}
                                                    >
                                                        {
                                                            product.VISAO_SELECIONADA
                                                        }{' '}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <TypographyP>Nenhuma visão alterada</TypographyP>
                )}
            </div>
        </ConfirmDialog>
    )
}

export default SaveViewDialog
