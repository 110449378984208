import { isAxiosError } from '@/api/business'
import { finishCost } from '@/api/business/structureStatus'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { costHistoryKeys } from '@/queries/useHistoryQuery'
import { structureStatusKeys } from '@/queries/useStructureStatusQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

type FinishCostDialogProps = {
    isOpen: boolean
    type: 'extra' | 'informed'
    onClose: () => void
}

const FinishCostDialog = ({ isOpen, type, onClose }: FinishCostDialogProps) => {
    const { id } = useParams()
    const queryClient = useQueryClient()
    const [observation, setObservation] = useState('')

    const { toast } = useToast()

    const { mutate, isLoading } = useMutation({
        mutationFn: finishCost,
        onSuccess: () => {
            if (id) {
                queryClient.invalidateQueries(structureStatusKeys.detail(id))
                queryClient.invalidateQueries(costHistoryKeys.detail(id))
            }
            toast({
                title: 'Custo finalizado com sucesso',
            })
            onClose()
        },
        onError(error) {
            let message = 'Erro ao finalizar custo'

            if (isAxiosError(error)) {
                message = error.response?.data.message || message
            }

            toast({
                title: 'Erro ao finalizar custo',
                description: message,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = () => {
        if (id) {
            mutate({
                id,
                type: type === 'extra' ? 'EXTRA' : 'INFORMADO',
                observation,
            })
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Finalizar custo</DialogTitle>
                </DialogHeader>
                <div className="space-y-1.5">
                    <Label>Observação</Label>
                    <Textarea
                        className="resize-none"
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                    />
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button onClick={onSubmit} disabled={isLoading}>
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default FinishCostDialog
