import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { getStdCostTableDataSelector } from '@/store/stdCostSlice'
import { CostView, StandardCost } from '@/types/Product'
import { DataOrigin, StandardCostTableData } from '@/types/StandardCost'
import {
    currencyFormat,
    numberFormat,
    percentageFormat,
} from '@/utils/stringFormatter'
import { Row, createColumnHelper } from '@tanstack/react-table'
import { AlertCircle, ArrowDown, ArrowUp, Minus } from 'lucide-react'
import { ReactNode, useMemo } from 'react'
import StdCostTableHeader from './components/StdCostTableHeader'
import StdCostTableActions from './components/StdCostTableActions'
import { Store } from '@/store/type'
import { statusLocale } from '@/types/Status'
import StructureStatus from '@/components/StructureStatus'
import { TypographyXS } from '@/components/ui/typography'

export type StandardCostTable = StandardCostTableData &
    TableCustomData<StandardCost>

const columnHelper = createColumnHelper<StandardCostTableData>()

const getSanitizedValue = (value: string) => value.toLowerCase().trim()

const ORIGIN: Record<DataOrigin, 'Fora de estrutura' | 'Manual' | 'Protheus'> =
    {
        FORA_DE_ESTRUTURA: 'Fora de estrutura',
        MANUAL: 'Manual',
        PROTHEUS: 'Protheus',
    }

const dataOriginClassname =
    'px-2 py-1 text-xs font-semibold rounded-md whitespace-nowrap'

const DataOriginComponent = {
    [CostView.CUSTO_INFORMADO]: (
        <span
            className={cn(
                dataOriginClassname,
                'border-green-500 text-green-500'
            )}
        >
            {CostView.CUSTO_INFORMADO}
        </span>
    ),
    [CostView.CUSTO_MEDIO]: (
        <span
            className={cn(
                dataOriginClassname,
                'border-orange-500 text-orange-500'
            )}
        >
            {CostView.CUSTO_MEDIO}
        </span>
    ),
    [CostView.ULTIMO_PRECO]: (
        <span
            className={cn(
                dataOriginClassname,
                'border-primary-500 text-primary-500'
            )}
        >
            {CostView.CUSTO_MEDIO}
        </span>
    ),
    [CostView.CUSTO_EXTRA]: (
        <span
            className={cn(
                dataOriginClassname,
                'text-xs font-semibold text-purple-400 border-purple-400 rounded-md'
            )}
        >
            {CostView.CUSTO_EXTRA}
        </span>
    ),
    [CostView.CUSTO_PADRAO]: (
        <span
            className={cn(
                dataOriginClassname,
                'text-xs font-semibold text-yellow-600 border-yellow-600 rounded-md'
            )}
        >
            {CostView.CUSTO_PADRAO}
        </span>
    ),
}

const columns = [
    columnHelper.accessor(
        ({ PRODUTO_ESTRUTURA, NK_PRODUTO_ESTRUTURA, TIPO_VISAO }) =>
            `${PRODUTO_ESTRUTURA} ${
                TIPO_VISAO !== CostView.CUSTO_EXTRA
                    ? `(${NK_PRODUTO_ESTRUTURA})`
                    : ''
            }`,
        {
            id: 'PRODUTO',
            header: 'Produto',
            cell: ({ row, getValue }) => {
                const {
                    DS_REVISAO_INICIAL,
                    DS_REVISAO_FINAL,
                    PRODUTO_APONTADO_BLOQUEADO,
                    productUsedAsComponentId,
                    DD_ORIGEM_DADO,
                } = row.original
                const isProduct = row.depth === 0

                const isNotDefault = DD_ORIGEM_DADO === 'FORA_DE_ESTRUTURA'

                return (
                    <div className="flex items-center flex-1 h-full gap-2 overflow-hidden">
                        <TypographyXS
                            className={cn(
                                'whitespace-nowrap flex-1 text-ellipsis overflow-hidden',
                                productUsedAsComponentId && 'text-yellow-600',
                                isNotDefault && 'text-red-400',
                                isProduct && 'text-primary',
                                PRODUTO_APONTADO_BLOQUEADO === 1 &&
                                    'line-through'
                            )}
                        >
                            {getValue()}
                        </TypographyXS>
                        {DS_REVISAO_INICIAL || DS_REVISAO_FINAL ? (
                            <TypographyXS
                                className={cn(
                                    'px-2 text-xs text-white text-center rounded-md bg-primary dark:bg-primary/40 dark:text-primary-200',
                                    productUsedAsComponentId && 'bg-yellow-500',
                                    isNotDefault && 'text-red-400'
                                )}
                            >
                                {DS_REVISAO_INICIAL && DS_REVISAO_INICIAL}
                                {DS_REVISAO_FINAL && `- ${DS_REVISAO_FINAL}`}
                            </TypographyXS>
                        ) : null}
                    </div>
                )
            },
            size: 350,
            enableGrouping: false,
            meta: {
                row: {
                    isGrouped: true,
                },
                cell: {
                    className: 'text-left',
                },
            },
            filterFn: (row, columnId, filterValue) => {
                const productFilter = getSanitizedValue(
                    row.original.PRODUTO_FILTRO
                )
                const product = getSanitizedValue(row.getValue(columnId) || '')

                return (
                    productFilter.includes(
                        getSanitizedValue(filterValue as string)
                    ) ||
                    product.includes(getSanitizedValue(filterValue as string))
                )
            },
        }
    ),
    columnHelper.accessor(
        ({ APROVACAO_ESTRUTURA }) =>
            APROVACAO_ESTRUTURA?.DS_STATUS
                ? statusLocale[APROVACAO_ESTRUTURA.DS_STATUS]
                : null,
        {
            id: 'APROVACAO_ESTRUTURA.DS_STATUS',
            header: 'Status estrutura',
            size: 150,
            cell: ({ row }) => {
                const { APROVACAO_ESTRUTURA } = row.original

                return (
                    APROVACAO_ESTRUTURA?.DS_STATUS && (
                        <StructureStatus
                            status={APROVACAO_ESTRUTURA.DS_STATUS}
                        />
                    )
                )
            },
            aggregatedCell: () => null,
            meta: {
                cell: {
                    className: 'text-left',
                },
            },
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ APROVACAO_CUSTO }) =>
            APROVACAO_CUSTO?.DS_STATUS
                ? statusLocale[APROVACAO_CUSTO.DS_STATUS]
                : null,
        {
            id: 'APROVACAO_CUSTO.DS_STATUS',
            header: 'Status custo',
            size: 150,
            cell: ({ row }) => {
                const { APROVACAO_CUSTO } = row.original

                return (
                    APROVACAO_CUSTO?.DS_STATUS && (
                        <StructureStatus status={APROVACAO_CUSTO.DS_STATUS} />
                    )
                )
            },
            aggregatedCell: () => null,
            meta: {
                cell: {
                    className: 'text-left',
                },
            },
            enableGrouping: false,
        }
    ),
    columnHelper.accessor('DD_CLASSIFICACAO', {
        id: 'DD_CLASSIFICACAO',
        header: 'Classificação',
        size: 60,
        meta: {
            cell: {
                className: 'text-left',
            },
        },
        enableGrouping: false,
    }),
    columnHelper.accessor('UM_PRODUTO_ESTRUTURA', {
        id: 'UM_PRODUTO_ESTRUTURA',
        header: 'UM',
        size: 60,
        meta: {
            cell: {
                className: 'text-left',
            },
        },
        enableGrouping: false,
    }),
    columnHelper.accessor(
        ({ CUSTO_SELECIONADO }) => currencyFormat(CUSTO_SELECIONADO, 6),
        {
            id: 'CUSTO_SELECIONADO',
            header: 'Custo Padrão',
            cell: ({ row, getValue }) => {
                return (
                    <p
                        className={cn(
                            'text-ellipsis overflow-hidden',
                            row.original.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO &&
                                row.original.CUSTO_SELECIONADO === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {getValue()}
                    </p>
                )
            },
            size: 150,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ CUSTO_PADRAO_REAL }) =>
            CUSTO_PADRAO_REAL
                ? currencyFormat(CUSTO_PADRAO_REAL, 6)
                : currencyFormat(0, 6),
        {
            id: 'CUSTO_PADRAO_REAL',
            header: 'C.P. Real',
            cell: ({ row, getValue }) => {
                const informedCost = Number(
                    row.original.VALOR_INFORMADO_PRODUTO
                )

                return (
                    <p
                        className={cn(
                            'text-ellipsis overflow-hidden',
                            row.original.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO && informedCost === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {getValue()}
                    </p>
                )
            },
            size: 150,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VALOR_CUSTO_REAL_COMPONENTE }) =>
            VALOR_CUSTO_REAL_COMPONENTE
                ? currencyFormat(VALOR_CUSTO_REAL_COMPONENTE, 6)
                : currencyFormat(0, 6),
        {
            id: 'VALOR_CUSTO_REAL_COMPONENTE',
            header: 'Custo Real',
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_CUSTO_PADRAO }) =>
            VARIACAO_CUSTO_PADRAO
                ? currencyFormat(VARIACAO_CUSTO_PADRAO, 6)
                : currencyFormat(0, 6),
        {
            id: 'VARIACAO_CUSTO_PADRAO',
            header: 'Var Custo Padrão',
            cell: ({ row, getValue }) => {
                const informedCost = Number(
                    row.original.VALOR_INFORMADO_PRODUTO
                )

                return (
                    <p
                        className={cn(
                            'text-ellipsis overflow-hidden',
                            row.original.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO && informedCost === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {getValue()}
                    </p>
                )
            },
            size: 150,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ CUSTO_PADRAO_TOTAL }) =>
            CUSTO_PADRAO_TOTAL
                ? currencyFormat(CUSTO_PADRAO_TOTAL, 6)
                : currencyFormat(0, 6),
        {
            id: 'CUSTO_PADRAO_TOTAL',
            header: 'C.P. Total',
            cell: ({ row, getValue }) => {
                const informedCost = Number(
                    row.original.VALOR_INFORMADO_PRODUTO
                )
                return (
                    <p
                        className={cn(
                            'text-ellipsis overflow-hidden',
                            row.original.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO && informedCost === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {getValue()}
                    </p>
                )
            },
            size: 160,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ CUSTO_PADRAO_TOTAL_REAL }) =>
            CUSTO_PADRAO_TOTAL_REAL
                ? currencyFormat(CUSTO_PADRAO_TOTAL_REAL, 6)
                : currencyFormat(0, 6),
        {
            id: 'CUSTO_PADRAO_TOTAL_REAL',
            header: 'C.P. Real Total',
            cell: ({ row, getValue }) => {
                const informedCost = Number(
                    row.original.VALOR_INFORMADO_PRODUTO
                )

                return (
                    <p
                        className={cn(
                            'text-ellipsis overflow-hidden',
                            row.original.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO && informedCost === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {getValue()}
                    </p>
                )
            },
            size: 160,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ CUSTO_TOTAL_REAL }) =>
            CUSTO_TOTAL_REAL
                ? currencyFormat(CUSTO_TOTAL_REAL, 6)
                : currencyFormat(0, 6),
        {
            id: 'CUSTO_TOTAL_REAL',
            header: 'Custo Real Total',
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_CUSTO_PADRAO_TOTAL }) =>
            currencyFormat(VARIACAO_CUSTO_PADRAO_TOTAL || 0, 6),
        {
            id: 'VARIACAO_CUSTO_PADRAO_TOTAL',
            header: 'Var. C.P. Real Total',
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL }) =>
            percentageFormat(
                Number(VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL || 0),
                6
            ),
        {
            id: 'VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL',
            header: 'Var. C.P. Real Total (%)',
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_CUSTO_REAL_TOTAL }) =>
            currencyFormat(VARIACAO_CUSTO_REAL_TOTAL || 0, 6),
        {
            id: 'VARIACAO_CUSTO_REAL_TOTAL',
            header: 'Var. C.R. Total',
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL }) =>
            percentageFormat(
                Number(VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL || 0),
                6
            ),
        {
            id: 'VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL',
            header: 'Var. C.R. Total (%)',
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VALOR_INFORMADO_PRODUTO }) =>
            VALOR_INFORMADO_PRODUTO
                ? currencyFormat(Number(VALOR_INFORMADO_PRODUTO), 6)
                : currencyFormat(0, 6),
        {
            id: 'VALOR_INFORMADO_PRODUTO',
            header: 'Custo Informado',
            size: 125,
            enableGrouping: false,
            meta: {
                cell: {
                    className: 'text-xs',
                },
            },
        }
    ),
    columnHelper.accessor(
        ({ ULTIMO_PRECO }) =>
            ULTIMO_PRECO
                ? currencyFormat(ULTIMO_PRECO, 6)
                : currencyFormat(0, 6),
        {
            id: 'ULTIMO_PRECO',
            header: 'Último Preço',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ ULTIMO_PRECO_UNITARIO }) =>
            ULTIMO_PRECO_UNITARIO
                ? currencyFormat(ULTIMO_PRECO_UNITARIO, 6)
                : currencyFormat(0, 6),
        {
            id: 'ULTIMO_PRECO_UNITARIO',
            header: 'Último Preço Uni.',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ CUSTO_PADRAO_UNITARIO }) =>
            CUSTO_PADRAO_UNITARIO
                ? currencyFormat(CUSTO_PADRAO_UNITARIO, 6)
                : currencyFormat(0, 6),
        {
            id: 'CUSTO_PADRAO_UNITARIO',
            header: 'Custo Uni. Std',
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VALOR_CUSTO_REAL_UNITARIO }) =>
            VALOR_CUSTO_REAL_UNITARIO
                ? currencyFormat(VALOR_CUSTO_REAL_UNITARIO, 6)
                : currencyFormat(0, 6),
        {
            id: 'VALOR_CUSTO_REAL_UNITARIO',
            header: 'Custo Uni. Real',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_CUSTO_UNITARIO }) =>
            currencyFormat(VARIACAO_CUSTO_UNITARIO || 0, 6),
        {
            id: 'VARIACAO_CUSTO_UNITARIO',
            header: 'Var Custo Uni.',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_CUSTO_UNITARIO_PERCENTUAL }) =>
            VARIACAO_CUSTO_UNITARIO_PERCENTUAL
                ? percentageFormat(VARIACAO_CUSTO_UNITARIO_PERCENTUAL, 6)
                : percentageFormat(0, 6),
        {
            id: 'VARIACAO_CUSTO_UNITARIO_PERCENTUAL',
            header: 'Var Custo Uni. (%)',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ IMPACTO_CONSUMO }) => currencyFormat(IMPACTO_CONSUMO || 0, 6),
        {
            id: 'IMPACTO_CONSUMO',
            header: 'Impacto Consumo',
            enableGrouping: false,
            size: 100,
        }
    ),
    columnHelper.accessor(
        ({ IMPACTO_PRECO }) => currencyFormat(IMPACTO_PRECO || 0, 6),
        {
            id: 'IMPACTO_PRECO',
            header: 'Impacto Preço',
            size: 100,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ INDICE_STANDARD }) => numberFormat(INDICE_STANDARD || 0, 6),
        {
            id: 'INDICE_STANDARD',
            header: 'Indice Std',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 100,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ INDICE_COMPONENTE_REAL }) =>
            numberFormat(INDICE_COMPONENTE_REAL || 0, 6),
        {
            id: 'INDICE_COMPONENTE_REAL',
            header: 'Indice Real',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 120,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_INDICE }) => numberFormat(VARIACAO_INDICE || 0, 6),
        {
            id: 'VARIACAO_INDICE',
            header: 'Var Indice',
            cell: ({ row, getValue }) => {
                const {
                    DD_VARIANCIA_MIN,
                    DD_VARIANCIA_MAX,
                    VARIACAO_PERCENTUAL_INDICE,
                } = row.original

                const min = DD_VARIANCIA_MIN || 0
                const max = DD_VARIANCIA_MAX || 0

                const hasValue = row.depth !== 0 && getValue()

                if (!hasValue) return null

                return (
                    <div className="flex items-center">
                        <div className="mr-1">
                            {VARIACAO_PERCENTUAL_INDICE < min && (
                                <ArrowDown className="text-red-500" size={14} />
                            )}
                            {VARIACAO_PERCENTUAL_INDICE > max && (
                                <ArrowUp className="text-red-500" size={14} />
                            )}
                            {VARIACAO_PERCENTUAL_INDICE >= min &&
                            VARIACAO_PERCENTUAL_INDICE <= max ? (
                                <Minus className="text-neutral-500" size={14} />
                            ) : null}
                        </div>
                        <div className="flex-1 overflow-hidden">
                            <TypographyXS>{getValue()}</TypographyXS>
                        </div>
                    </div>
                )
            },
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_PERCENTUAL_INDICE }) =>
            percentageFormat(Number(VARIACAO_PERCENTUAL_INDICE), 6),
        {
            id: 'VARIACAO_PERCENTUAL_INDICE',
            header: 'Var Indice (%)',
            cell: ({ row, getValue }) => {
                const {
                    DD_VARIANCIA_MIN,
                    DD_VARIANCIA_MAX,
                    VARIACAO_PERCENTUAL_INDICE,
                } = row.original

                const min = DD_VARIANCIA_MIN || 0
                const max = DD_VARIANCIA_MAX || 0

                const hasValue = row.depth !== 0 && getValue()

                if (!hasValue) return null

                return (
                    <div className="flex items-center">
                        <div className="mr-1">
                            {VARIACAO_PERCENTUAL_INDICE < min && (
                                <ArrowDown className="text-red-500" size={14} />
                            )}
                            {VARIACAO_PERCENTUAL_INDICE > max && (
                                <ArrowUp className="text-red-500" size={14} />
                            )}
                            {VARIACAO_PERCENTUAL_INDICE >= min &&
                            VARIACAO_PERCENTUAL_INDICE <= max ? (
                                <Minus className="text-neutral-500" size={14} />
                            ) : null}
                        </div>
                        <div className="flex-1 overflow-hidden">
                            <TypographyXS>{getValue()}</TypographyXS>
                        </div>
                    </div>
                )
            },
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ DD_VARIANCIA_MAX }) => percentageFormat(DD_VARIANCIA_MAX || 0, 6),
        {
            id: 'DD_VARIANCIA_MAX',
            header: 'Var Máx',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ DD_VARIANCIA_MIN }) => percentageFormat(DD_VARIANCIA_MIN || 0, 6),
        {
            id: 'DD_VARIANCIA_MIN',
            header: 'Var Min',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 125,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ QTD_COMPONENTE }) =>
            QTD_COMPONENTE
                ? numberFormat(QTD_COMPONENTE, 6)
                : numberFormat(0, 6),
        {
            id: 'qtde',
            header: 'Qtde Std',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 100,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ QTD_REAL_UTILIZADA }) => numberFormat(QTD_REAL_UTILIZADA || 0, 6),
        {
            id: 'QTD_REAL_UTILIZADA',
            header: 'Qtde Real',
            cell: ({ row, getValue }) => {
                const hasChanged = !!row.original.PRODUTO_QTD_SELECIONADA
                return (
                    <div
                        className={cn(
                            hasChanged && 'flex items-center gap-2 text-red-600'
                        )}
                    >
                        {getValue() && (
                            <>
                                {row.depth === 0
                                    ? getValue().split(',')[0]
                                    : getValue()}
                                {hasChanged && <AlertCircle size={14} />}
                            </>
                        )}
                    </div>
                )
            },
            size: 100,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_QUANTIDADE_TOTAL }) =>
            numberFormat(VARIACAO_QUANTIDADE_TOTAL, 6),
        {
            id: 'VARIACAO_QUANTIDADE_TOTAL',
            header: 'Var Qtde',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 100,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor(
        ({ VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL }) =>
            percentageFormat(Number(VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL), 6),
        {
            id: 'VARIACAO_PERCENTUAL_QUANTIDADE_TOTAL',
            header: 'Var Qtde (%)',
            cell: ({ row, getValue }) => {
                return (
                    row.depth !== 0 && <TypographyXS>{getValue()}</TypographyXS>
                )
            },
            size: 100,
            enableGrouping: false,
        }
    ),
    columnHelper.accessor('TIPO_VISAO', {
        id: 'TIPO_VISAO',
        header: 'Visão',
        cell: ({ row, getValue }) => {
            let Badge: ReactNode | null = null

            const original = row.original

            let value: CostView | null

            if (original.productUsedAsComponentId) {
                value = CostView.CUSTO_PADRAO
            } else {
                value = original.VISAO_SELECIONADA
                    ? original.VISAO_SELECIONADA
                    : getValue()
            }

            if (original.subRows.length === 0) {
                if (value) Badge = DataOriginComponent[value]
            }

            return Badge
        },
        meta: {
            cell: {
                className: 'items-center text-center',
            },
        },
        aggregatedCell: ({ getValue }) => {
            const value = getValue()

            return value && DataOriginComponent[value]
        },
        enableGrouping: false,
        size: 120,
    }),
    columnHelper.accessor(
        ({ DD_ORIGEM_DADO }) => DD_ORIGEM_DADO && ORIGIN[DD_ORIGEM_DADO],
        {
            id: 'DD_ORIGEM_DADO',
            header: 'Origem',
            cell: ({ getValue }) => {
                const value = getValue()

                return (
                    value && (
                        <span
                            className={cn(
                                'px-2 py-1 text-xs rounded-lg',
                                value === 'Manual' &&
                                    'text-green-600 rounded-lg bg-green-50 dark:bg-emerald-600/30 dark:text-emerald-400',
                                value === 'Protheus' &&
                                    ' text-cyan-600 bg-cyan-50 dark:bg-cyan-600/30 dark:text-cyan-400',
                                value === 'Fora de estrutura' &&
                                    'text-red-600 rounded-lg bg-red-50 dark:bg-red-600/30 dark:text-red-400'
                            )}
                        >
                            {value}
                        </span>
                    )
                )
            },
            aggregatedCell: ({ getValue }) => {
                const value = getValue()

                return (
                    value && (
                        <span
                            className={cn(
                                'px-2 py-1 text-xs rounded-lg',
                                value === 'Manual' &&
                                    'text-green-600 rounded-lg bg-green-50 dark:bg-emerald-600/30 dark:text-emerald-400',
                                value === 'Protheus' &&
                                    ' text-cyan-600 bg-cyan-50 dark:bg-cyan-600/30 dark:text-cyan-400',
                                value === 'Fora de estrutura' &&
                                    'text-red-600 rounded-lg bg-red-50 dark:bg-red-600/30 dark:text-red-400'
                            )}
                        >
                            {value}
                        </span>
                    )
                )
            },
            size: 100,
            enableGrouping: false,
            meta: {
                cell: {
                    className: 'flex items-center justify-center',
                },
            },
        }
    ),
]

const stateSelector = (state: Store) => ({
    stdCostData: state.stdCostSlice.state.stdCostData,
    stdCostSelectedCompany: state.stdCostSlice.state.stdCostSelectedCompany,
    selectedStdCostRow: state.stdCostSlice.state.selectedStdCostRow,
})

const actionsSelector = (state: Store) => ({
    onSelectStdCostRow: state.stdCostSlice.actions.onSelectStdCostRow,
    onAddTableInstance: state.stdCostSlice.actions.onAddTableInstance,
})

const StdCostsTable = () => {
    const {
        stdCostData,
        stdCostSelectedCompany: selectedCompany,
        selectedStdCostRow: selectedRow,
    } = useBaseStore(stateSelector)

    const { onSelectStdCostRow: selectRow, onAddTableInstance } =
        useBaseStore(actionsSelector)

    const stdCostTable = useBaseStore(getStdCostTableDataSelector)
    const columnsMemo = useMemo(() => columns, [])

    const dataMemo: StandardCostTableData[] = useMemo(
        () => stdCostTable,
        [selectedCompany, stdCostData.components, stdCostData.products]
    )

    const onRowClick = ({ row }: { row: Row<StandardCostTableData> }) => {
        row.toggleSelected()
        selectRow({
            id: row.id,
            isProduct: row.depth === 0,
        })
    }

    return (
        <Table
            tableId={selectedCompany || ''}
            data={dataMemo}
            columns={columnsMemo}
            selectedRowId={selectedRow?.id}
            onRowClick={onRowClick}
            getRowId={selectedCompany ? (data) => data._id : undefined}
            getTableInstance={onAddTableInstance}
            tableHeader={<StdCostTableHeader />}
            tableActions={<StdCostTableActions />}
            shouldRenderCheckbox={false}
            tableState={{
                rowSelection: selectedRow ? { [selectedRow.id]: true } : {},
            }}
            enableRowSelection
            persist={{
                canPersist: true,
                key: 'std-costs-table',
                omit: ['rowSelection'],
            }}
            meta={{
                cell: {
                    className: 'text-right',
                },
                row: {
                    className: 'cursor-pointer',
                },
            }}
        />
    )
}

export default StdCostsTable
