import { useFormContext, useWatch } from 'react-hook-form'
import { AddSchema } from '../..'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useEffect, useMemo } from 'react'
import VirtualizedCommand from '@/components/VirtualizedCommand'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { TypographyP } from '@/components/ui/typography'

const ComponentSelect = () => {
    const { control } = useFormContext<AddSchema>()

    const {
        data: productStructureData,
        isLoading,
        isError,
    } = useProductStructureQuery({
        enabled: true,
    })

    const form = useFormContext<AddSchema>()
    const companyField = useWatch({ name: 'companyId' })
    const productField = useWatch({ name: 'productId' })

    const products = useMemo(() => {
        if (companyField && productStructureData) {
            const unique: Record<string, (typeof productStructureData)[0]> = {}

            const filtered = productStructureData.filter(
                (product) => product.SK_EMPRESA.toString() === companyField
            )

            for (const product of filtered) {
                if (!unique[product.SK_PRODUTO_COMPONENTE]) {
                    unique[product.SK_PRODUTO_COMPONENTE] = product
                }
            }

            return Object.values(unique)
        } else {
            return []
        }
    }, [companyField, productStructureData])

    useEffect(() => {
        if (!companyField) {
            form.resetField('productId')
        }
    }, [companyField])

    return (
        <FormField
            name="componentId"
            control={control}
            render={({ field }) => {
                let label = 'Selecione um componente'

                if (isLoading) {
                    label = 'Carregando...'
                } else if (isError) {
                    label = 'Erro ao carregar dados'
                } else if (!companyField) {
                    label = 'Selecione uma empresa'
                } else if (!productField) {
                    label = 'Selecione um produto'
                } else if (field.value) {
                    const product = productStructureData?.find(
                        (comp) => comp.SK_PRODUTO_COMPONENTE == field.value
                    )
                    label = product?.PRODUTO_COMPONENTE || label
                }
                return (
                    <FormItem>
                        <FormControl>
                            <>
                                <FormLabel>Componente estrutura</FormLabel>
                                <Popover>
                                    <PopoverTrigger
                                        asChild
                                        disabled={isLoading || isError}
                                    >
                                        <Button
                                            type="button"
                                            variant="outline"
                                            className="flex justify-start w-full overflow-hidden"
                                            disabled={isLoading || isError}
                                        >
                                            <TypographyP className="flex-1 overflow-hidden text-start text-ellipsis">
                                                {label}
                                            </TypographyP>
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent
                                        className="z-50 w-[400px] p-0"
                                        align="start"
                                    >
                                        <VirtualizedCommand
                                            onSelectionChange={(value) => {
                                                field.onChange(value)
                                            }}
                                            options={products.map(
                                                (component) => {
                                                    return {
                                                        value: component.SK_PRODUTO_COMPONENTE,
                                                        label: component.PRODUTO_COMPONENTE,
                                                    }
                                                }
                                            )}
                                            selectedValues={{
                                                [field.value]: true,
                                            }}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
            }}
        />
    )
}

export default ComponentSelect
