import { isAxiosError } from '@/api/business'
import { finishStructure } from '@/api/business/structureStatus'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { costHistoryKeys } from '@/queries/useHistoryQuery'
import { structureStatusKeys } from '@/queries/useStructureStatusQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

type FinishStructureDialogProps = {
    isOpen: boolean
    onClose: () => void
}

const FinishStructureDialog = ({
    isOpen,
    onClose,
}: FinishStructureDialogProps) => {
    const { id } = useParams()
    const [observation, setObservation] = useState('')

    const queryClient = useQueryClient()

    const { toast } = useToast()

    const { mutate, isLoading } = useMutation({
        mutationFn: finishStructure,
        onSuccess: () => {
            if (id) {
                queryClient.invalidateQueries(structureStatusKeys.detail(id))
                queryClient.invalidateQueries(costHistoryKeys.detail(id))
            }
            toast({
                title: 'Estrutura finalizada com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            const message = 'Erro ao finalizar estrutura'

            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao finalizar estrutura',
                        description: message,
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const onSubmit = () => {
        if (id) {
            mutate({ id, observation })
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Finalizar estrutura</DialogTitle>
                </DialogHeader>
                <div className="space-y-1.5">
                    <Label>Observação</Label>
                    <Textarea
                        className="resize-none"
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                    />
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={onSubmit}
                        disabled={isLoading}
                        className="w-[100px]"
                    >
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default FinishStructureDialog
