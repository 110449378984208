import { MudancaCusto } from '@/api/business/structureStatus/type'
import { TypographyP, TypographyXS } from '@/components/ui/typography'
import { ALTERATION_ICON, ALTERATION_TITLE, HistoryText } from '../HistoryText'
import { format } from 'date-fns'
import { getDateFromId } from '@/utils/date'
import CostDetailDialog from '../CostDetailDialog'
import useDisclosure from '@/hooks/useDisclosure'

type HistoryItemProps = {
    item: MudancaCusto
}

const HistoryItem = ({ item }: HistoryItemProps) => {
    const {
        DD_TIPO_CUSTO,
        PRODUTO_COMPONENTE,
        NM_USUARIO,
        SK_TEMPO,
        TIPO_ALTERACAO,
        DATA_APROVACAO,
        DS_STATUS,
        UPDATED_AT,
    } = item

    const { isOpen, onOpen, onClose } = useDisclosure()

    const isExtraProduct = DD_TIPO_CUSTO === 'EXTRA-PRODUTO'

    const productOrComponent = isExtraProduct ? 'Produto' : PRODUTO_COMPONENTE

    return (
        <>
            <div
                className="p-4 mb-4 transition duration-300 rounded-lg shadow-sm cursor-pointer bg-accent hover:bg-slate-200 dark:hover:bg-slate-900"
                onClick={onOpen}
            >
                <div className="flex items-center justify-between">
                    <div className="flex items-center flex-1 gap-2">
                        {ALTERATION_ICON[TIPO_ALTERACAO](DD_TIPO_CUSTO)}
                        <TypographyP className="font-semibold text-accent-foreground">
                            {ALTERATION_TITLE[TIPO_ALTERACAO]()}
                        </TypographyP>
                    </div>
                    <TypographyXS className="text-xs text-muted-foreground">
                        {format(
                            new Date(
                                TIPO_ALTERACAO === 'STATUS_ALTERADO'
                                    ? DATA_APROVACAO
                                    : UPDATED_AT
                            ),
                            'dd LLL yy, HH:mm:ss'
                        )}
                    </TypographyXS>
                </div>
                <div className="p-3 mt-2 border rounded-lg bg-background">
                    <TypographyP className="text-foreground">
                        {NM_USUARIO}{' '}
                        <HistoryText
                            alterationType={TIPO_ALTERACAO}
                            entity={productOrComponent}
                            status={DS_STATUS}
                        />
                        <span className="text-muted-foreground">
                            no período
                        </span>{' '}
                        {format(getDateFromId(SK_TEMPO), 'MM/yyyy')}
                    </TypographyP>
                </div>
            </div>
            <CostDetailDialog item={item} isOpen={isOpen} onClose={onClose} />
        </>
    )
}

export default HistoryItem
