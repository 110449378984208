import { useFormContext, useWatch } from 'react-hook-form'
import { AddSchema } from '../..'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'

import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useEffect, useMemo, useState } from 'react'
import VirtualizedCommand from '@/components/VirtualizedCommand'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { TypographyP } from '@/components/ui/typography'

const ProductSelect = () => {
    const { control } = useFormContext<AddSchema>()
    const [selectedComponents, setSelectedComponents] = useState<
        Record<string, boolean>
    >({})

    const {
        data: componentStructureData = [],
        isLoading,
        isError,
    } = useProductStructureQuery({
        enabled: true,
    })

    const form = useFormContext<AddSchema>()

    const companyField = useWatch({ name: 'companyId' })
    const componentField = useWatch({ name: 'componentId' })
    const productField = useWatch({ name: 'productId' })

    const components = useMemo(() => {
        if (companyField && componentStructureData) {
            const unique: Record<string, (typeof componentStructureData)[0]> =
                {}

            const filtered = componentStructureData.filter(
                (component) =>
                    component.SK_EMPRESA.toString() === companyField &&
                    component.SK_PRODUTO_COMPONENTE === componentField
            )

            for (const component of filtered) {
                if (!unique[component.SK_PRODUTO_ESTRUTURA]) {
                    unique[component.SK_PRODUTO_ESTRUTURA] = component
                }
            }

            return Object.values(unique)
        } else {
            return []
        }
    }, [companyField, componentField, componentStructureData])

    useEffect(() => {
        if (!companyField || !componentField) {
            form.resetField('componentId')
        }
    }, [componentField, companyField])

    useEffect(() => {
        form.setValue('productId', [])
    }, [componentField])

    return (
        <FormField
            name="productId"
            control={control}
            render={({ field }) => {
                let label = 'Selecione um componente'
                const product = Object.entries(selectedComponents).filter(
                    ([, value]) => value
                ).length

                if (isLoading) {
                    label = 'Carregando...'
                } else if (isError) {
                    label = 'Erro ao carregar dados'
                } else if (!companyField) {
                    label = 'Selecione uma empresa'
                } else if (!productField) {
                    label = 'Selecione um produto'
                } else if (product > 0) {
                    label = `${product} selecionados` || label
                }

                return (
                    <FormItem>
                        <FormControl>
                            <>
                                <FormLabel>Produto estrutura</FormLabel>
                                <Popover>
                                    <PopoverTrigger
                                        asChild
                                        disabled={isLoading || isError}
                                    >
                                        <Button
                                            type="button"
                                            variant="outline"
                                            className="flex justify-start w-full overflow-hidden"
                                        >
                                            <TypographyP className="flex-1 overflow-hidden text-start text-ellipsis">
                                                {label}
                                            </TypographyP>
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent
                                        className="z-50 w-[400px] p-0"
                                        align="start"
                                    >
                                        <VirtualizedCommand
                                            onSelectionChange={(value) => {
                                                setSelectedComponents(
                                                    (prev) => {
                                                        const newValue = {
                                                            ...prev,
                                                            [value]:
                                                                !prev[value],
                                                        }

                                                        field.onChange(
                                                            Object.entries(
                                                                newValue
                                                            )
                                                                .filter(
                                                                    ([, val]) =>
                                                                        val
                                                                )
                                                                .map(
                                                                    ([key]) =>
                                                                        key
                                                                )
                                                        )

                                                        return newValue
                                                    }
                                                )
                                            }}
                                            options={components.map(
                                                (component) => {
                                                    return {
                                                        value: component.SK_PRODUTO_ESTRUTURA,
                                                        label: component.PRODUTO_ESTRUTURA,
                                                    }
                                                }
                                            )}
                                            selectedValues={selectedComponents}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
            }}
        />
    )
}

export default ProductSelect
