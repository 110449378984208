import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { memo, useEffect } from 'react'

const stateSelector = (state: Store) => ({
    companies: state.stdCostSlice.state.companies,
    stdCostSelectedCompany: state.stdCostSlice.state.stdCostSelectedCompany,
})

const actionsSelector = (state: Store) => ({
    onSelectStdCostCompany: state.stdCostSlice.actions.onSelectStdCostCompany,
})

const StdCostTableHeader = memo(() => {
    const { companies, stdCostSelectedCompany: selectedCompany } =
        useBaseStore(stateSelector)

    const { onSelectStdCostCompany: selectCompany } =
        useBaseStore(actionsSelector)

    useEffect(() => {
        if (companies) {
            selectCompany(selectedCompany || companies[0]?.SK_EMPRESA || '')
        }
    }, [companies])

    return (
        <div className="flex gap-1">
            {companies.map((company) => (
                <Button
                    key={company.SK_EMPRESA}
                    className={cn(
                        selectedCompany !== company.SK_EMPRESA &&
                            'text-muted-foreground'
                    )}
                    variant={
                        selectedCompany !== company.SK_EMPRESA
                            ? 'ghost'
                            : 'default'
                    }
                    onClick={() =>
                        selectCompany(company.SK_EMPRESA?.toString() || '')
                    }
                >
                    {company.ABREVIATURA_EMPRESA}
                </Button>
            ))}
        </div>
    )
})

export default StdCostTableHeader
