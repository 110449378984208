import { useToast } from '@/components/ui/use-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useInformedCostSpreadsheet } from '../../useInformedCostSpreadsheet'
import { createColumnHelper } from '@tanstack/react-table'
import { TableData } from '@/components/Table/type'
import useBulkDialog, { BulkDialogActionNames } from './useBulkDialog'
import { ChangeEvent, useMemo, useState } from 'react'
import { useCurrencyQuery } from '@/queries/useCurrencyQuery'
import Select from '@/components/Select'
import { createBulkInformedCost } from '@/api/business/informedCost'
import { informedCostKeys } from '@/queries/useInformedCostQuery'
import {
    ArrowUpToLine,
    Download,
    Info,
    ListPlus,
    Paintbrush,
    Search,
    X,
} from 'lucide-react'
import useDisclosure from '@/hooks/useDisclosure'
import { isAxiosError } from '@/api/business'
import { cn } from '@/lib/utils'
import ResponseDialog from './components/ResponseDialog'
import Table from '@/components/Table'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { DatePicker } from '@/components/DatePicker'
import { getDateFromId, getIdFromDate } from '@/utils/date'
import { isValid } from 'date-fns'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import ProductStructureDialog from '../ProductStructureDialog'
import { Freight, Taxes } from '@/types/Costs'
import { structureStatusKeys } from '@/queries/useStructureStatusQuery'
import { costHistoryKeys } from '@/queries/useHistoryQuery'
interface BulkDialogProps {
    isOpen: boolean
    onClose: () => void
}

export type BulkData = {
    NK_PRODUTO_ESTRUTURA: string
    PRODUTO_ESTRUTURA?: string
    NK_PRODUTO_COMPONENTE: string
    PRODUTO_COMPONENTE?: string
    SK_EMPRESA: string
    SK_TEMPO: number
    SK_MOEDA: number
    VL_CUSTO_ORCADO: string
    DD_TIPO_FRETE: Freight
    DD_OBSERVACAO?: string
    STATUS?: number
    MENSAGEM?: string
    id: string
} & Taxes

type BulkTable = TableData<BulkData>

const columnHelper = createColumnHelper<BulkData>()

const BulkDialog = ({ isOpen, onClose }: BulkDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const { tableData, feedbackResponse, productsLoading, dispatch } =
        useBulkDialog()
    const [replaceOnChange, setReplaceOnChange] = useState(true)

    const { onDownloadBulkModel, onReadBulkData } = useInformedCostSpreadsheet()
    const { data: currencyData, isLoading: isCurrencyLoading } =
        useCurrencyQuery()

    const {
        isOpen: isFeedbackDialogOpen,
        onClose: onFeedbackDialogClose,
        onOpen: onFeedbackDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isProductStructureDialogOpen,
        onClose: onProductStructureDialogClose,
        onOpen: onProductStructureDialogOpen,
    } = useDisclosure()

    // const [bulkTableInstance, setBulkTableInstance] =
    //     useState<TableType<BulkTable>>()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: createBulkInformedCost,
        onSuccess: (data) => {
            queryClient.invalidateQueries(informedCostKeys.lists())
            queryClient.invalidateQueries(structureStatusKeys.all)
            queryClient.invalidateQueries(costHistoryKeys.all)

            dispatch({
                type: BulkDialogActionNames.SET_DATA_STATUS,
                payload: data.filter((product) => product.STATUS !== 200),
            })
            dispatch({
                type: BulkDialogActionNames.RESET_TABLE_DATA,
            })
            onFeedbackDialogOpen()
            toast({
                title: 'Carga realizada com sucesso',
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else if (err.response?.status === 406) {
                    toast({
                        title: 'Produtos sem estrutura',
                        description:
                            'Todos os produtos inseridos não possuem uma estrutura válida.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao realizar carga',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const dataMemo = useMemo(() => tableData, [tableData])

    const columnsMemo = useMemo(
        () => [
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    return (
                        <div className="flex items-center justify-center h-full p-0 text-red-500">
                            <button
                                onClick={() =>
                                    dispatch({
                                        type: BulkDialogActionNames.REMOVE_ROW,
                                        payload: { id: row.original.id },
                                    })
                                }
                            >
                                <X size={16} />
                            </button>
                        </div>
                    )
                },
                meta: {
                    cell: {
                        className:
                            'flex items-center justify-center p-0 text-red-500',
                    },
                },
                size: 35,
                enableResizing: false,
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Cod Empresa',
                size: 90,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('NK_PRODUTO_ESTRUTURA', {
                id: 'NK_PRODUTO_ESTRUTURA',
                header: 'Cod Produto',
                size: 90,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('PRODUTO_ESTRUTURA', {
                id: 'PRODUTO_ESTRUTURA',
                header: 'Produto',
                cell: ({ getValue }) => {
                    return (
                        <div
                            className={'p-1 text-xs flex items-center h-full '}
                        >
                            <div
                                className={cn(
                                    'overflow-hidden whitespace-nowrap text-ellipsis',
                                    !getValue() && 'text-red-500'
                                )}
                            >
                                {getValue()
                                    ? getValue()
                                    : 'Produto não encontrado'}
                            </div>
                        </div>
                    )
                },
                size: 200,
            }),
            columnHelper.accessor('NK_PRODUTO_COMPONENTE', {
                id: 'NK_PRODUTO_COMPONENTE',
                header: 'Cod componente',
                size: 90,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('PRODUTO_COMPONENTE', {
                id: 'PRODUTO_COMPONENTE',
                header: 'Componente',
                cell: ({ getValue }) => {
                    return (
                        <div className="flex items-center h-full p-1 text-xs">
                            <div
                                className={cn(
                                    'overflow-hidden whitespace-nowrap text-ellipsis',
                                    !getValue() && 'text-red-500'
                                )}
                            >
                                {getValue()
                                    ? getValue()
                                    : 'Produto não encontrado'}
                            </div>
                        </div>
                    )
                },
                size: 200,
            }),
            columnHelper.accessor('SK_TEMPO', {
                id: 'SK_TEMPO',
                header: 'Data',
                size: 180,
                cell: ({ getValue, column, row }) => {
                    let date = new Date()

                    const number = Number(getValue())

                    if (getValue()) {
                        if (Number.isNaN(number)) {
                            if (isValid(new Date(date)))
                                date = new Date(getValue())
                        } else {
                            date = getDateFromId(Number(getValue()))
                        }
                    }

                    return (
                        <DatePicker
                            date={date}
                            className="h-full text-xs"
                            setDate={(date) => {
                                dispatch({
                                    type: BulkDialogActionNames.UPDATE_TABLE_DATA,
                                    payload: {
                                        columnId: column.id,
                                        rowIdx: row.index,
                                        value:
                                            date &&
                                            getIdFromDate(date).join(''),
                                    },
                                })
                            }}
                        />
                    )
                },
            }),
            columnHelper.accessor('SK_MOEDA', {
                id: 'SK_MOEDA',
                header: 'Moeda',
                cell: ({ row, column, getValue }) => {
                    return (
                        <Select
                            onChange={(value) =>
                                dispatch({
                                    type: BulkDialogActionNames.UPDATE_TABLE_DATA,
                                    payload: {
                                        columnId: column.id,
                                        rowIdx: row.index,
                                        value,
                                    },
                                })
                            }
                            options={
                                currencyData
                                    ? currencyData?.map((currency) => ({
                                          label: currency.DS_MOEDA,
                                          value: currency.SK_MOEDA.toString(),
                                      }))
                                    : []
                            }
                            value={getValue() ? getValue().toString() : '1'}
                            className="h-full px-1 text-xs"
                            placeholder={
                                isCurrencyLoading ? 'Carregando moedas...' : ''
                            }
                        />
                    )
                },
                meta: {
                    cell: {
                        className: 'p-0',
                    },
                },
                size: 80,
                enableSorting: false,
            }),
            columnHelper.accessor('DD_TIPO_FRETE', {
                id: 'DD_TIPO_FRETE',
                header: 'Frete',
                cell: ({ row, column, getValue }) => {
                    return (
                        <Select
                            onChange={(value) =>
                                dispatch({
                                    type: BulkDialogActionNames.UPDATE_TABLE_DATA,
                                    payload: {
                                        columnId: column.id,
                                        rowIdx: row.index,
                                        value,
                                    },
                                })
                            }
                            options={[
                                { label: 'CIF', value: 'CIF' },
                                { label: 'FOB', value: 'FOB' },
                            ]}
                            value={getValue() ? getValue() : 'CIF'}
                            className="h-full px-1 text-xs"
                        />
                    )
                },
                size: 80,
            }),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                size: 400,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('VL_CUSTO_ORCADO', {
                id: 'VL_CUSTO_ORCADO',
                header: 'Custo',
                size: 100,
                meta: {
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value, row }) =>
                            currencyFormat(value, 6, row.original.DS_MOEDA),
                        className: 'p-0 justify-end flex',
                    },
                },
            }),
            columnHelper.accessor('VL_PIS', {
                id: 'VL_PIS',
                header: 'PIS',
                size: 80,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('VL_ALIQUOTA_PIS', {
                id: 'VL_ALIQUOTA_PIS',
                header: 'PIS (%)',
                size: 100,
                meta: {
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) => percentageFormat(value, 6),
                    },
                },
            }),
            columnHelper.accessor('VL_COFINS', {
                id: 'VL_COFINS',
                header: 'COFINS',
                size: 100,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('VL_ALIQUOTA_COFINS', {
                id: 'VL_ALIQUOTA_COFINS',
                header: 'COFINS (%)',
                size: 120,
                meta: {
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) => percentageFormat(value, 6),
                    },
                },
            }),
            columnHelper.accessor('VL_FCP', {
                id: 'VL_FCP',
                header: 'FCP',
                size: 80,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('VL_ALIQUOTA_FCP', {
                id: 'VL_ALIQUOTA_FCP',
                header: 'FCP (%)',
                size: 100,
                meta: {
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) => percentageFormat(value, 6),
                    },
                },
            }),
            columnHelper.accessor('VL_ICMS', {
                id: 'VL_ICMS',
                header: 'ICMS',
                size: 80,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('VL_ALIQUOTA_ICMS', {
                id: 'VL_ALIQUOTA_ICMS',
                header: 'ICMS (%)',
                size: 110,
                meta: {
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) => percentageFormat(value, 6),
                    },
                },
            }),
            columnHelper.accessor('VL_IPI', {
                id: 'VL_IPI',
                header: 'IPI',
                size: 80,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('VL_ALIQUOTA_IPI', {
                id: 'VL_ALIQUOTA_IPI',
                header: 'IPI (%)',
                size: 100,
                meta: {
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) => percentageFormat(value, 6),
                    },
                },
            }),
            columnHelper.accessor('VL_ST', {
                id: 'VL_ST',
                header: 'ST',
                size: 80,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('VL_ALIQUOTA_ST', {
                id: 'VL_ALIQUOTA_ST',
                header: 'ST (%)',
                size: 100,
                meta: {
                    enableColumnEditable: true,
                    cell: {
                        formatterFn: ({ value }) => percentageFormat(value, 6),
                    },
                },
            }),
        ],
        [currencyData, isCurrencyLoading]
    )

    const onSubmit = () => {
        mutate({
            products: tableData.map((data) => ({
                ...data,
                SK_PRODUTO_ESTRUTURA: `${data.SK_EMPRESA}${data.NK_PRODUTO_ESTRUTURA}`,
                SK_PRODUTO_COMPONENTE: `${data.SK_EMPRESA}${data.NK_PRODUTO_COMPONENTE}`,
            })),
        })
    }

    const onChangeFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files
        if (file && file[0]) {
            const data = await onReadBulkData(file[0])

            dispatch({
                type: BulkDialogActionNames.SET_TABLE_DATA,
                payload: { data, replace: true },
            })
        }
    }

    return (
        <>
            <Dialog open={isOpen} onOpenChange={onClose}>
                <DialogContent className="flex flex-col h-full max-w-full rounded-none">
                    <DialogHeader>
                        <DialogTitle className="m-0">
                            Carga custo informado
                        </DialogTitle>
                        <div className="flex items-center justify-end gap-2">
                            {dataMemo.length > 0 && (
                                <Button
                                    className="flex items-center justify-center w-10 h-10 p-0 bg-indigo-500 rounded-md hover:bg-indigo-600"
                                    onClick={() =>
                                        dispatch({
                                            type: BulkDialogActionNames.ADD_ROW,
                                        })
                                    }
                                >
                                    <ListPlus size={16} />
                                </Button>
                            )}
                            <Button
                                className="gap-2"
                                variant="outline"
                                onClick={onProductStructureDialogOpen}
                            >
                                <Search
                                    size={16}
                                    className="text-neutral-500"
                                />
                                Buscar estrutura
                            </Button>
                            <Button
                                className="gap-2"
                                variant="outline"
                                onClick={() =>
                                    dispatch({
                                        type: BulkDialogActionNames.RESET_ALL,
                                    })
                                }
                            >
                                <Paintbrush
                                    size={16}
                                    className="text-amber-500"
                                />
                                Resetar
                            </Button>
                            <Button
                                disabled={productsLoading}
                                asChild
                                variant="outline"
                                className="gap-2"
                            >
                                <label
                                    className="cursor-pointer"
                                    htmlFor="file"
                                    onClick={(e) => {
                                        if (productsLoading) {
                                            return e.preventDefault()
                                        }
                                    }}
                                >
                                    <ArrowUpToLine
                                        size={16}
                                        className="text-primary"
                                    />
                                    Importar planilha
                                </label>
                            </Button>
                            <input
                                className="hidden"
                                id="file"
                                type="file"
                                onChange={onChangeFileInput}
                            />
                            <Button
                                className="gap-2"
                                onClick={() => onDownloadBulkModel(tableData)}
                            >
                                <Download size={16} />
                                Download
                            </Button>
                        </div>
                    </DialogHeader>
                    <div className="flex flex-col flex-1 w-full space-y-4 overflow-auto">
                        <div className="flex-1 w-full overflow-hidden">
                            {dataMemo.length > 0 && columnsMemo ? (
                                <Table<BulkTable>
                                    data={dataMemo}
                                    columns={columnsMemo}
                                    meta={{
                                        updateData: (
                                            rowIdx,
                                            columnId,
                                            value
                                        ) => {
                                            dispatch({
                                                type: BulkDialogActionNames.UPDATE_TABLE_DATA,
                                                payload: {
                                                    rowIdx: Number(rowIdx),
                                                    columnId,
                                                    value,
                                                },
                                            })
                                        },
                                        cell: {
                                            className: 'px-0 py-0 items-center',
                                        },
                                    }}
                                />
                            ) : (
                                <div className="flex justify-center w-full">
                                    <TypographyP>
                                        Nenhum dado importado
                                    </TypographyP>
                                </div>
                            )}
                        </div>
                        {dataMemo.some(
                            (item) =>
                                !item.SK_EMPRESA ||
                                !item.NK_PRODUTO_COMPONENTE ||
                                !item.NK_PRODUTO_ESTRUTURA
                        ) && (
                            <div className="flex items-center self-end gap-2 text-orange-500">
                                <Info size={14} />
                                <TypographyP>
                                    Há itens com dados faltantes
                                </TypographyP>
                            </div>
                        )}
                    </div>
                    <DialogFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button disabled={mutateLoading} onClick={onSubmit}>
                            Confirmar
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <ResponseDialog
                isOpen={isFeedbackDialogOpen}
                onClose={onFeedbackDialogClose}
                response={feedbackResponse}
            />
            <ProductStructureDialog
                isOpen={isProductStructureDialogOpen}
                onClose={onProductStructureDialogClose}
                onSelect={(product) => {
                    onProductStructureDialogClose()

                    const newData = product.COMPONENTES.map((component) => ({
                        id: crypto.randomUUID(),
                        NK_PRODUTO_COMPONENTE: component.NK_COMPONENTE,
                        NK_PRODUTO_ESTRUTURA: product.NK_PRODUTO,
                        SK_EMPRESA: product.SK_EMPRESA.toString(),
                        SK_MOEDA: 1,
                        SK_TEMPO: Number(getIdFromDate(new Date()).join('')),
                        VL_ALIQUOTA_COFINS: '0',
                        VL_ALIQUOTA_FCP: '0',
                        VL_ALIQUOTA_ICMS: '0',
                        VL_ALIQUOTA_IPI: '0',
                        VL_ALIQUOTA_PIS: '0',
                        VL_ALIQUOTA_ST: '0',
                        VL_COFINS: '0',
                        VL_CUSTO_ORCADO: '0',
                        VL_FCP: '0',
                        VL_ICMS: '0',
                        VL_IPI: '0',
                        VL_PIS: '0',
                        VL_ST: '0',
                        DD_TIPO_FRETE: 'CIF' as const,
                        DD_OBSERVACAO: '',
                    }))

                    dispatch({
                        type: BulkDialogActionNames.SET_TABLE_DATA,
                        payload: { data: newData, replace: replaceOnChange },
                    })
                }}
                onReplaceChange={setReplaceOnChange}
                replace={replaceOnChange}
            />
        </>
    )
}

export default BulkDialog
