import { Table } from '@tanstack/react-table'
import { InformedCostTable } from '.'
import { read, utils, writeFile } from 'xlsx'
import { useToast } from '@/components/ui/use-toast'
import { InformedCost } from '@/types/Costs'
import {
    BulkProduct,
    CreateBulkInformedCostProps,
} from '@/api/business/informedCost/types'
import { useCurrencyQuery } from '@/queries/useCurrencyQuery'
import { format } from 'date-fns'
import { BulkData } from './components/BulkDialog'

const BULK_HEADERS: {
    original: keyof BulkData
    header: string
}[] = [
    { original: 'SK_EMPRESA', header: 'COD_EMPRESA' },
    { original: 'NK_PRODUTO_ESTRUTURA', header: 'COD_PRODUTO_ESTRUTURA' },
    { original: 'PRODUTO_ESTRUTURA', header: 'PRODUTO' },
    { original: 'NK_PRODUTO_COMPONENTE', header: 'COD_PRODUTO_COMPONENTE' },
    { original: 'PRODUTO_COMPONENTE', header: 'COMPONENTE' },
    { original: 'SK_TEMPO', header: 'DATA' },
    { original: 'SK_MOEDA', header: 'MOEDA' },
    { original: 'VL_CUSTO_ORCADO', header: 'VALOR' },
    { original: 'DD_TIPO_FRETE', header: 'FRETE' },
    { original: 'DD_OBSERVACAO', header: 'OBSERVAÇÃO' },
    {
        original: 'VL_ALIQUOTA_COFINS',
        header: 'VL_ALIQUOTA_COFINS',
    },
    { original: 'VL_COFINS', header: 'VL_COFINS' },
    { original: 'VL_ALIQUOTA_FCP', header: 'VL_ALIQUOTA_FCP' },
    { original: 'VL_FCP', header: 'VL_FCP' },
    { original: 'VL_ALIQUOTA_ICMS', header: 'VL_ALIQUOTA_ICMS' },
    { original: 'VL_ICMS', header: 'VL_ICMS' },
    { original: 'VL_ALIQUOTA_IPI', header: 'VL_ALIQUOTA_IPI' },
    { original: 'VL_IPI', header: 'VL_IPI' },
    { original: 'VL_ALIQUOTA_PIS', header: 'VL_ALIQUOTA_PIS' },
    { original: 'VL_PIS', header: 'VL_PIS' },
    { original: 'VL_ALIQUOTA_ST', header: 'VL_ALIQUOTA_ST' },
    { original: 'VL_ST', header: 'VL_ST' },
]

const HEADERS: {
    original: keyof InformedCost
    header: string
}[] = [
    { original: 'SK_EMPRESA', header: 'COD_EMPRESA' },
    { original: 'NK_PRODUTO_ESTRUTURA', header: 'COD_PRODUTO' },
    { original: 'PRODUTO_ESTRUTURA', header: 'PRODUTO' },
    { original: 'NK_PRODUTO_COMPONENTE', header: 'COD_PRODUTO_COMPONENTE' },
    { original: 'PRODUTO_COMPONENTE', header: 'PRODUTO COMPONENTE' },
    { original: 'SK_TEMPO', header: 'DATA' },
    { original: 'SK_MOEDA', header: 'MOEDA' },
    { original: 'DD_TIPO_FRETE', header: 'FRETE' },
    { original: 'DD_OBSERVACAO', header: 'OBSERVAÇÃO' },
    { original: 'VL_CUSTO_ORCADO', header: 'VALOR' },
    {
        original: 'VL_ALIQUOTA_COFINS',
        header: 'VL_ALIQUOTA_COFINS',
    },
    { original: 'VL_COFINS', header: 'VL_COFINS' },
    { original: 'VL_ALIQUOTA_FCP', header: 'VL_ALIQUOTA_FCP' },
    { original: 'VL_FCP', header: 'VL_FCP' },
    { original: 'VL_ALIQUOTA_ICMS', header: 'VL_ALIQUOTA_ICMS' },
    { original: 'VL_ICMS', header: 'VL_ICMS' },
    { original: 'VL_ALIQUOTA_IPI', header: 'VL_ALIQUOTA_IPI' },
    { original: 'VL_IPI', header: 'VL_IPI' },
    { original: 'VL_ALIQUOTA_PIS', header: 'VL_ALIQUOTA_PIS' },
    { original: 'VL_PIS', header: 'VL_PIS' },
    { original: 'VL_ALIQUOTA_ST', header: 'VL_ALIQUOTA_ST' },
    { original: 'VL_ST', header: 'VL_ST' },
    { original: 'CREATED_AT', header: 'CRIADO EM' },
    { original: 'UPDATED_AT', header: 'ATUALIZADO EM' },
]

export const useInformedCostSpreadsheet = (
    tableInstance?: Table<InformedCostTable>
) => {
    const { toast } = useToast()
    const { data: currencyData } = useCurrencyQuery()

    const getTableRows = (
        table: Table<InformedCostTable>
    ): Partial<InformedCost>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(row.original)) {
                const header = HEADERS.find((h) => h.original === key)?.header

                if (header && value) {
                    if (key === 'CREATED_AT' || key === 'UPDATED_AT') {
                        obj[header] = format(
                            new Date(value),
                            'dd/MM/yyyy HH:mm:ss'
                        )
                    } else {
                        obj[header] = value.toString()
                    }
                }
            }

            return obj
        })

    const getHeaders = (table: Table<InformedCostTable>): string[] =>
        table
            .getFlatHeaders()
            .map(
                (header) =>
                    BULK_HEADERS.find((h) => header.id === h.original)
                        ?.header || ''
            )
            .filter((header) => Boolean(header))

    const onDownload = () => {
        if (!tableInstance)
            return toast({
                title: 'Erro ao fazer download',
                description:
                    'Houve um erro inesperado ao fazer o download da planilha. Tente novamente ou contate o suporte.',
                variant: 'destructive',
            })

        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Custo informado')
        writeFile(workbook, 'Custo informado.xlsx', {
            compression: true,
        })
    }

    const onDownloadBulkModel = (tableData?: BulkData[]) => {
        const headers = BULK_HEADERS.map((header) => header.header)

        const worksheet =
            tableData && tableData.length > 0
                ? utils.json_to_sheet(
                      tableData.map((data) => {
                          const obj = {} as Record<string, any>

                          for (const [key, value] of Object.entries(data).sort(
                              ([keyA], [keyB]) => {
                                  const idxA = BULK_HEADERS.findIndex(
                                      (h) => h.original === keyA
                                  )
                                  const idxB = BULK_HEADERS.findIndex(
                                      (h) => h.original === keyB
                                  )

                                  return idxA > idxB ? 1 : -1
                              }
                          )) {
                              const header = BULK_HEADERS.find(
                                  (h) => h.original === key
                              )?.header

                              if (header) {
                                  if (header === 'MOEDA') {
                                      obj[header] =
                                          currencyData?.find(
                                              (currency) =>
                                                  currency.SK_MOEDA === value
                                          )?.DS_MOEDA || 'REAL'
                                  } else {
                                      obj[header] = value.toString()
                                  }
                              }
                          }

                          return obj as CreateBulkInformedCostProps
                      })
                  )
                : utils.aoa_to_sheet([headers])

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Custo informado')

        writeFile(workbook, 'CustoInformado.xlsx', {
            compression: true,
        })
    }

    const onReadBulkData = async (sheetFile: File) => {
        const data = await sheetFile.arrayBuffer()
        const workbook = read(data, {
            type: 'binary',
            cellDates: true,
            cellNF: false,
            cellText: false,
        })

        const sheet = workbook.Sheets[workbook.SheetNames[0]]

        const sheetJson = utils.sheet_to_json<BulkData>(sheet)

        return sheetJson.map((sheetData) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(sheetData)) {
                const original = BULK_HEADERS.find(
                    (h) => h.header === key.trim()
                )?.original

                if (original) {
                    if (original === 'SK_MOEDA') {
                        obj[original] =
                            currencyData?.find(
                                (currency) => currency.DS_MOEDA === value
                            )?.SK_MOEDA || 1
                    } else {
                        obj[original] = value.toString()
                    }
                }
            }

            return obj as BulkData
        })
    }
    const onReadData = async (sheetFile: File) => {
        const data = await sheetFile.arrayBuffer()
        const workbook = read(data, {
            type: 'binary',
            cellDates: true,
            cellNF: false,
            cellText: false,
        })

        const sheet = workbook.Sheets[workbook.SheetNames[0]]

        const sheetJson = utils.sheet_to_json<BulkProduct>(sheet)

        return sheetJson.map((sheetData) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(sheetData)) {
                const original = BULK_HEADERS.find(
                    (h) => h.header === key.trim()
                )?.original

                if (original) {
                    if (original === 'SK_MOEDA') {
                        obj[original] =
                            currencyData?.find(
                                (currency) => currency.DS_MOEDA === value
                            )?.SK_MOEDA || 1
                    } else {
                        obj[original] = value.toString()
                    }
                }
            }

            return obj as BulkProduct
        })
    }

    return {
        getHeaders,
        onDownloadBulkModel,
        onDownload,
        onReadBulkData,
        onReadData,
    }
}
